import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PAGES from "../../constants/pages";
import { workflow_get, update_workflow } from "../../actions/workflowAction";
import {
  WORKFLOW,
  WORKFLOW_TYPE,
  WORKFLOW_VALUE,
} from "../../constants/workflow";
import {
  get_user_attributes_get,
  post_user_attributes_post,
} from "../../actions/userAction";
import WorkflowLayout from "./workflow_layout";
import PageLoader from "../global/page-loader";
import { utils_workflow } from "../../utils/utils_workflow";
import { utils } from "../../utils/utils_general";
import { get_survey_by_id_post } from "../../actions/surveyAction";
import { RESPONSE_CODE, UNEXPECTED_ERROR } from "../../constants/errors";
import { utils_registration_flow } from "../../utils/utils_registration_flow";
import WorkflowContentRenderer from "./workflow_content_renderer";
import usePrevious from "../../utils/usePrevious";

function checkAttributesShouldSkipComponent(step, attr) {
  const entry_attr_errors = utils_registration_flow.checkAttrCompleted(
    step.entry_attribute,
    attr,
  );
  const exit_attr_incompleted = utils_registration_flow.checkAttrCompleted(
    step.exit_attribute,
    attr,
  );
  return entry_attr_errors.length > 0 || exit_attr_incompleted.length === 0;
}

function EnrollmentWorkflow({
  workflow,
  update_workflow,
  workflow_get,
  history,
  match,
  get_user_attributes_get,
  get_survey_by_id_post,
  post_user_attributes_post,
  t,
}) {
  const [body, setBody] = useState(null);
  const [step, setStep] = useState(null);
  const [, setErrors] = useState(null);
  const [surveyUrl, setSurveyUrl] = useState();

  const previous = usePrevious({
    workflow,
    componentId: match.params.component_id,
  });

  // First render.
  useEffect(() => {
    if (workflow && !utils.is_obj_empty(workflow)) {
      setupBody();
    } else {
      const workflow = utils_workflow.getWorkflowFromStorage(update_workflow);
      if (!workflow) {
        workflow_get();
      }
    }
  }, []);

  // Listen to changes in the Workflow
  useEffect(() => {
    const { workflow: prevWorkflow } = previous || {};
    if (
      workflow &&
      !utils.is_obj_empty(workflow) &&
      !utils.check_objects_identical(workflow, prevWorkflow)
    ) {
      setupBody();
    }
  }, [workflow, previous]);

  // Listen to changes in the URL
  useEffect(() => {
    const { componentId } = previous || {};
    const currentComponentId = match.params.component_id;

    if (
      workflow &&
      componentId !== undefined &&
      componentId !== currentComponentId
    ) {
      setupBody();
    }
  }, [workflow, match.params.component_id, previous]);

  async function setupBody() {
    const { component_id: componentId } = match.params;
    const stepBasedOnId = componentId
      ? workflow.find((c) => c.id === componentId)
      : null;
    if (!stepBasedOnId) {
      return history.push(PAGES.NOT_FOUND);
    }
    const dbAttr = await get_user_attributes_get();

    const stepBasedOnIdEntryErrors = utils_registration_flow.checkAttrCompleted(
      stepBasedOnId.entry_attribute,
      dbAttr,
    );
    const stepBasedOnIdExitErrors = utils_registration_flow.checkAttrCompleted(
      stepBasedOnId.exit_attribute,
      dbAttr,
    );

    if (
      stepBasedOnIdEntryErrors?.length ||
      stepBasedOnIdExitErrors?.length === 0
    ) {
      return history.push(PAGES.ENROLLMENT);
    }
    // check entry attributes - if entry not met or exit complete
    if (checkAttributesShouldSkipComponent(stepBasedOnId, dbAttr)) {
      return history.push(PAGES.ENROLLMENT);
    }
    setBody(stepBasedOnId ? stepBasedOnId.body : null);
    setStep(stepBasedOnId);

    if (stepBasedOnId.component === WORKFLOW.SURVEY) {
      getSurveyDetails(stepBasedOnId.survey_id);
    }
  }

  async function getSurveyDetails(surveyId) {
    if (!surveyId) {
      setErrors("Survey not found");
      return;
    }

    try {
      const response = await get_survey_by_id_post(surveyId);
      setSurveyUrl(response);
    } catch (error) {
      if (
        error.response &&
        error.response.status === RESPONSE_CODE["404_notfound"]
      ) {
        setErrors("Survey Unavailable");
      } else {
        setErrors(UNEXPECTED_ERROR);
      }
    }
  }

  async function onComponentComplete() {
    // ugh some components handle their own submissions others dont
    // we need to submit attributes here for components:
    // - text_media
    const attr = step.exit_attribute[0].split("|")[0];
    await post_user_attributes_post(attr, true);
    onFormComplete();
  }

  async function onFormComplete() {
    // check attribute is complete
    const res = await get_user_attributes_get();

    const attr_errors = utils_registration_flow.checkAttrCompleted(
      step.exit_attribute,
      res,
    );

    if (attr_errors.length > 0) {
      console.log("attrerrors", attr_errors);
    } else {
      const currentStepIndex = workflow.findIndex((c) => c.id === step.id);

      // Complete the step in the workflow
      setStep(workflow[currentStepIndex]);

      const attr_errors = utils_registration_flow.checkAttrCompleted(
        step.exit_attribute,
        res,
      );
      if (attr_errors.length > 0) {
        // TODO: show error
        console.log("attr_errors", attr_errors);
      } else {
        const newFlow = workflow.map((c) => {
          if (c.id === step.id) {
            c.value = WORKFLOW_VALUE.COMPLETE;
          }

          // unlock next step progress card
          if (c.entry_attribute[0] !== "*") {
            const attr_errors = utils_registration_flow.checkAttrCompleted(
              c.entry_attribute,
              res,
            );
            if (attr_errors.length > 0) {
              c.locked = true;
            } else {
              c.locked = false;
            }
          }
          return c;
        });
        update_workflow(newFlow);
      }

      goNext();
    }
  }

  function goNext() {
    const currentStep = step;
    const currentStepIndex = workflow.findIndex((c) => c.id === step.id);
    // if next card is the same "Step", go to next component
    if (
      currentStepIndex &&
      currentStepIndex < workflow.length - 1 &&
      currentStep.step === workflow[currentStepIndex + 1].step
    ) {
      utils_workflow.goToNextWorkflowStep(
        workflow,
        step,
        update_workflow,
        history.push,
        WORKFLOW_TYPE.ENROLLMENT,
      );
    } else {
      // if the step is done, go to the enrollment overview
      history.push(`${PAGES.ENROLLMENT}`);
    }
  }

  if (!step) {
    return <PageLoader loader={!step} loaderMessage={t("loading data")} />;
  }
  const aside = step;
  return (
    <>
      <PageLoader loader={!workflow} loaderMessage={t("loading data")} />
      <WorkflowLayout aside={aside}>
        <WorkflowContentRenderer
          body={body}
          step={step}
          surveyUrl={surveyUrl}
          onFormComplete={() => onFormComplete()}
          onComponentComplete={() => onComponentComplete()}
        />
      </WorkflowLayout>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, {
    workflow_get,
    update_workflow,
    get_user_attributes_get,
    post_user_attributes_post,
    get_survey_by_id_post,
  })(withTranslation(["workflow", "texts"])(EnrollmentWorkflow)),
);
