import { useTranslation } from "react-i18next";
import AppLogo from "../../../assets/images/app-logo.svg";
import { CONFIG, hasLanguageSwitcher } from "../../../constants/config";
import PAGES from "../../../constants/pages";
import { useState } from "react";
import LanguageSwitch from "../../global/language_switch";

export default function ReferralLandingPageBHeader() {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <div className="col-12 unauth-header-wrapper unauth-referral-header">
      <div className="unauth-header-logo">
        <a href="/">
          <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
        </a>
      </div>
      <div
        className={`landing-header-menu-hamburger ${mobileMenuOpen ? "_open" : "_closed"}`}
        onClick={toggleMobileMenu}
      >
        <span className="hamburger-strip" />
        <span className="hamburger-strip" />
        <span className="hamburger-strip" />
      </div>
      <div
        className={`unauth-header-landing-menu ${mobileMenuOpen ? "_open" : "_closed"}`}
      >
        {hasLanguageSwitcher ? (
          <div className="landing-header-menu-language">
            <LanguageSwitch />
          </div>
        ) : null}
        <div className="landing-header-menu-buttons">
          <a href={PAGES.SIGNUP} className="_highlighted">
            {t("Sign up now")}
          </a>
        </div>
      </div>
    </div>
  );
}
