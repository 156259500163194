import { TextMediaWithNext } from "../../global/text_media_with_next";
import { WorkflowStepProps } from "../../../services/types/WorkflowTypes";

function TextMediaWrapper({
  currentStep,
  onComponentComplete,
}: WorkflowStepProps) {
  const { body } = currentStep;

  return <TextMediaWithNext body={body} onNext={onComponentComplete} />;
}

export { TextMediaWrapper };
