import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectWorkflowByCode,
  selectWorkflowIsLoading,
} from "../../store/Workflow/selector";

import {
  GenericWorkflow,
  WorkflowCodeType,
} from "../../services/types/WorkflowTypes";
import { useInjection } from "../../services/ServiceProvider";
import { IWorkflowService } from "../../services/WorkflowService";

import WorkflowComponent from "./WorkflowComponent";

type WorkflowWrapperStateProps = {
  workflow?: GenericWorkflow | null;
  isWorkflowLoading?: boolean;
};

type WorkflowWrapperProps = {
  workflowCode: WorkflowCodeType | null;
  workflowName?: string;
  onWorkflowExit?: () => Promise<any>;
} & WorkflowWrapperStateProps;

export function WorkflowWrapperComponent({
  workflowCode,
  workflowName,
  workflow,
  isWorkflowLoading = false,
  onWorkflowExit,
}: WorkflowWrapperProps) {
  const workflowService: IWorkflowService = useInjection("workflowService");
  const [isEscaped, setIsEscaped] = useState(false);

  useEffect(() => {
    if (workflowCode && !isWorkflowLoading) {
      workflowService.getWorkflow(workflowCode);
    }
  }, [workflowCode, workflowService]);

  const handleWorkflowExit = useCallback(async () => {
    if (isWorkflowLoading || !workflow) {
      return null;
    }
    setIsEscaped(true);
    if (typeof onWorkflowExit === "function") {
      return onWorkflowExit();
    }
    return null;
  }, [workflow, isWorkflowLoading]);

  const handleWorkflowSkip = useCallback(async () => {
    console.log("Need to skip Workflow!");
    if (isWorkflowLoading || !workflow) {
      return null;
    }
    if (!workflowCode) {
      return null;
    }
    await workflowService.skipWorkflow(workflowCode);
    if (typeof onWorkflowExit === "function") {
      return onWorkflowExit();
    }
    setIsEscaped(true);
  }, [workflow, isWorkflowLoading]);

  if (isEscaped) {
    return null;
  }

  return (
    <div className="test_workflowWrapper">
      <WorkflowComponent
        workflow={workflow || null}
        workflowCode={workflowCode || null}
        isWorkflowLoading={isWorkflowLoading}
        workflowName={workflowName || null}
        onWorkflowExit={handleWorkflowExit}
        onWorkflowSkip={handleWorkflowSkip}
      />
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<
  any,
  WorkflowWrapperStateProps
>({
  workflow: selectWorkflowByCode,
  isWorkflowLoading: selectWorkflowIsLoading,
});

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowWrapperComponent);
