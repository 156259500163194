import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
/* eslint-disable camelcase */
import { get_emr_workflow } from "../../actions/emrAction";
import { utils_workflow } from "../../utils/utils_workflow";
import {
  get_user_attributes_get,
  post_user_attributes_post,
} from "../../actions/userAction";
import { utils_registration_flow } from "../../utils/utils_registration_flow";
import PAGES from "../../constants/pages";
import { EMRConnectLayout } from "./emr_connect_layout";
import { EMR2 } from "./emr2";
/* eslint-enable camelcase */

class EMRConnectWorkflow extends React.Component {
  constructor() {
    super();
    this.state = {
      body: null,
      id: null,
      step: null,
      errors: null,
      updated: false,
      showIntro: false,
    };
  }

  async componentDidMount() {
    // get emr workflow and check entry attributes
    await Promise.all([
      this.props.get_emr_workflow(),
      this.props.get_user_attributes_get(),
    ]);
    const emrComponent =
      this.props.workflow?.find((c) => c.component === "emr") || {};
    const { entry_attribute = [] } = emrComponent;
    const attributeErrors = utils_registration_flow.checkAttrCompleted(
      entry_attribute,
      this.props.userAttribute,
    );
    if (attributeErrors && attributeErrors.length) {
      // redirect back to dashboard if any entry attributes dont match
      this.props.history.push(PAGES.DASHBOARD);
    }
  }

  async onFormComplete() {
    // update user attributes with workflow exit attributes
    const emrComponent =
      this.props.workflow.find((c) => c.component === "emr") || {};
    const { exit_attribute = [] } = emrComponent;
    const exitAttributes = utils_workflow.getPostAttributes(
      exit_attribute.filter((attr) => attr !== "*"),
    );

    try {
      await Promise.all(
        Object.entries(exitAttributes).map(([attr, value]) =>
          this.props.post_user_attributes_post(attr, value),
        ),
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.props.history.push(PAGES.DASHBOARD);
    }
  }

  handleShowIntro = (showIntro) => {
    this.setState({ showIntro });
  };

  render() {
    // This is were EMR is used from the profile section
    if (!this.props.workflow) return null;
    const aside = {
      name: this.props.t("Connect EMR"),
      details: {
        subtitle: this.props.t("Add or re-connect to EMR providers"),
        allow_pause: true,
      },
    };

    return (
      <EMRConnectLayout
        flowName="EMR"
        aside={aside}
        introShown={
          this.state.showIntro || !this.props.userAttribute?.emr_confirmed
        }
        onShowIntro={this.handleShowIntro}
      >
        <EMR2
          onComplete={() => this.onFormComplete()}
          showIntro={this.state.showIntro}
          onShowIntro={this.handleShowIntro}
        />
      </EMRConnectLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  workflow: state.emrConnections.workflow,
  userAttribute: state.userAttribute,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, {
    get_emr_workflow,
    get_user_attributes_get,
    post_user_attributes_post,
  })(withTranslation(["workflow", "texts"])(EMRConnectWorkflow)),
);
