import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { withTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import { utils } from "../../utils/utils_general";
import { LOCAL_STORAGE } from "../../constants/localStorage";
import {
  FIELD_ERROR_DISPLAY,
  SIGNUP_ERROR_DISPLAY,
} from "../../constants/errors";
import { WORKFLOW, WORKFLOW_VALUE } from "../../constants/workflow";
import { LINKS } from "../../constants/links";
import {
  verify_post,
  submit_verification_post,
} from "../../actions/verificationAction";
import { update_user_put } from "../../actions/userAction";
import { login_get } from "../../actions/loginAction";
import { update_registration_flow } from "../../actions/registrationFlowAction";
import { withService } from "../../services/ServiceProvider";

const LOCAL_STORAGE_VERIFICATION = "rgc.VERIFICATION";
const VERIFICATION_INFORMATION_ATTEMPTS = 4;

class Verification extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      firstname: "",
      lastname: "",
      address: "",
      zip: "",
      errors: {},
      verification_questions: null,
      verification_quesitons_counter: 0,
      current_verification_answer: null,
      verification_answers: [],
      verification_id: null,
      showFailPage: false,
      loader: false,
      // reg_flow: null
    };
    this.handleSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.firstNameField = React.createRef();
    this.lastNameField = React.createRef();
    this.addressField = React.createRef();
    this.zipcodeField = React.createRef();
  }

  componentDidMount() {
    this.checkAttempts();
    this.checkUserRegistrationToken();
    i18n.loadNamespaces("fields");
  }

  componentDidUpdate(prevProps) {
    // for i18n idology we use translations coming from backend
    // so if the language is changed - we reload the page
    // TODO: replace data w/o reloading if needed
    if (prevProps.language !== this.props.language) {
      window.location.reload();
    }
  }

  onFormComplete() {
    const flow =
      this.props.registrationFlow ||
      utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (flow) {
      flow.map((c) => {
        if (c.component === WORKFLOW.VERIFICATION) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
        return c;
      });
      return this.props.onComplete(flow);
    }
  }

  checkUserRegistrationToken() {
    const { userService } = this.props;

    const user = userService.getCurrentUser();

    if (user && new Date(user.expiry) > new Date() && user.email) {
      if (user.kba_locked) {
        this.setState({ showFailPage: true });
      }
      this.setState({
        email: user.email,
        firstname: user.first_name ? utils.titlecase(user.first_name) : "",
        lastname: user.last_name ? utils.titlecase(user.last_name) : "",
        zip: user.zip ? utils.titlecase(user.zip) : "",
      });
    } else {
      this.setState({ errors: { system: SIGNUP_ERROR_DISPLAY.TOKEN_INVALID } });
    }
  }

  showServerErrors(formErrors) {
    const errorMessageKeys = {
      "No first name submitted": "firstname",
      "No last name submitted": "lastname",
      "No address submitted": "address",
      "Either zip code or city and state are required": "zip",
    };

    const normalizedErrors = formErrors.reduce((accumulate, current) => {
      if (!current || !errorMessageKeys.hasOwnProperty(current)) {
        return accumulate;
      }

      return {
        ...accumulate,
        [errorMessageKeys[current]]: current,
      };
    }, {});

    this.showErrors(normalizedErrors);
  }

  showErrors(formErrors) {
    const nonXssErrorMessages = {
      firstname: this.props.t("Please enter your first name."),
      lastname: this.props.t("Please enter your last name."),
      address: this.props.t("Please enter your street name."),
      zip: this.props.t("Please a valid zip code."),
    };
    const errors = Object.entries(formErrors).reduce(
      (accumulate, [key, value]) => ({
        ...accumulate,
        [key]:
          value === "error-potential-xss"
            ? FIELD_ERROR_DISPLAY.err_field_potential_xss
            : nonXssErrorMessages[key],
      }),
      {},
    );

    this.setState({ errors }, () => {
      this.focusField();
    });
    this.checkAttempts();
  }

  checkAttempts() {
    const tries = utils.get_local_storage(LOCAL_STORAGE_VERIFICATION);
    if (tries && tries > VERIFICATION_INFORMATION_ATTEMPTS) {
      this.setState({ showFailPage: true });
      this.props.onFail();
    } else {
      utils.set_local_storage(
        LOCAL_STORAGE_VERIFICATION,
        tries ? tries + 1 : 1,
      );
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      zip: this.state.zip,
      address: this.state.address,
    };
    // frontend check error
    /**
     * @type {{validationService: ValidationService }}
     */
    const { validationService } = this.props;

    const errorList =
      validationService.validateData("VERIFICATION_VALIDATION", data)?.error
        ?.details || [];

    const errors = errorList.reduce(
      (accumulate, { path, message }) => ({
        ...accumulate,
        [path[0]]: message,
      }),
      {},
    );

    this.showErrors(errors);

    if (utils.is_obj_empty(errors)) {
      this.setState({ loader: true });
      this.props
        .verify_post(data, true, this.props.language)
        .then((response) => {
          this.setState({ loader: false });
          // idology check error
          const form_errors = response.data.response.error;
          if (form_errors) {
            this.showErrors(form_errors);
          } else {
            const resp = response.data.response;
            const verification_questions = resp.questions
              ? resp.questions[0].question
              : null;
            const verification_id = resp["id-number"][0];
            if (verification_questions) {
              this.setState({ verification_questions, verification_id });
              utils.set_local_storage(LOCAL_STORAGE_VERIFICATION, 0);
            } else {
              this.checkAttempts();
              if (
                resp["idliveq-error"][0].message[0] ===
                "Not Eligible For Questions"
              ) {
                this.setState({
                  errors: {
                    system: `Something's not right, please check the information that you provided.`,
                  },
                });
              }
            }
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            errors: {
              system: "Something's not right, please try again later.",
            },
          });
          console.log(error);
        });
    }
  }

  handleChange(e) {
    if (
      !this.state.errors.system ||
      this.state.errors.system !== this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID)
    ) {
      this.setState({ [e.target.name]: e.target.value, errors: {} });
    }
  }

  handleFocus = (event) => event.target.select();

  renderFormGroup(
    label,
    name,
    defaultState,
    focusField,
    autoFocus = undefined,
  ) {
    const { t } = this.props;
    const { errors } = this.state;
    return (
      <Form.Group controlId={name}>
        <Form.Label>{t(label)}</Form.Label>
        <Form.Control
          onInput={(e) => this.handleChange(e)}
          name={name}
          type="text"
          defaultValue={defaultState}
          aria-required="true"
          ref={focusField}
          onFocus={this.handleFocus}
          autoFocus={autoFocus}
        />
        <Form.Text className="text-danger form-error">
          {errors[name] ? t(errors[name]) : ""}
        </Form.Text>
      </Form.Group>
    );
  }

  renderVerificationForm() {
    const { errors, loader, firstname, lastname, address, zip } = this.state;

    const enableSubmit = utils.is_obj_empty(errors) && !loader;

    return (
      <>
        <div className="mb-3">
          <Trans i18nKey="verificationText">
            <p className="mb-1">
              We take pride in safeguarding personal information. That’s why we
              ask you to verify your identity before accessing your account.
              This ensures that your data really does belong to you.
            </p>
            <p className="mb-1">
              To verify your identity, you will need to provide some basic
              information, like your name and address. This is similar to the
              identity verification experiences used by financial, healthcare,
              and other high-security entities.
            </p>
            <p>
              In some cases, additional information may be required, but only
              the minimum amount needed to verify your identity.
            </p>
          </Trans>
        </div>
        <div className="mb-3">
          {this.t("Please enter the following information.")}
        </div>
        <Form
          noValidate
          onSubmit={(e) =>
            enableSubmit ? this.handleFormSubmit(e) : e.preventDefault()
          }
        >
          <div className="row">
            <div className="col-12 col-sm-6 pr-sm-2">
              {this.renderFormGroup(
                "First Name",
                "firstname",
                firstname,
                this.firstNameField,
                true,
              )}
            </div>
            <div className="col-12 col-sm-6 pl-sm-2">
              {this.renderFormGroup(
                "Last Name",
                "lastname",
                lastname,
                this.lastNameField,
              )}
            </div>
          </div>

          {this.renderFormGroup(
            "Address",
            "address",
            address,
            this.addressField,
          )}
          {this.renderFormGroup("Zip Code", "zip", zip, this.zipcodeField)}
          <br />
          <div className="unauth-body-footer">
            <Button
              variant={enableSubmit ? "primary" : "disable"}
              type="submit"
              block
            >
              {this.t("Verify")}
            </Button>
            <div className="text-center">
              <Form.Text className="text-danger form-error" aria-live="polite">
                {errors.system ? this.t(errors.system) : ""}
              </Form.Text>
            </div>
          </div>
        </Form>
      </>
    );
  }

  handleAnsweringVerification(e) {
    this.setState({ current_verification_answer: e.target.value, errors: {} });
  }

  submitVerificationQuestion(e, type, answer) {
    e.preventDefault();
    const answers = this.state.verification_answers;
    answers.push({ type, answer });
    this.setState({
      verification_answers: answers,
      current_verification_answer: null,
    });
    if (
      this.state.verification_quesitons_counter <
      this.state.verification_questions.length - 1
    ) {
      const counter = this.state.verification_quesitons_counter + 1;
      this.setState({ verification_quesitons_counter: counter });
    } else {
      this.setState({ loader: true });
      // went through all questions
      this.props
        .submit_verification_post(this.state.verification_id, answers)
        .then((resp) => {
          this.setState({ loader: false });
          if (
            resp.data.response["iq-summary-result"] &&
            resp.data.response["iq-summary-result"][0] &&
            resp.data.response["iq-summary-result"][0] === "pass"
          ) {
            this.props
              .update_user_put({ verif_user_id: this.state.verification_id })
              .then(() =>
                this.props.login_get().then(() => {
                  this.onFormComplete();
                }),
              );
          } else {
            this.setState({ showFailPage: true });
            this.props.onFail();
            this.props
              .update_user_put({ status: "kba_not_verified" })
              .then(() => this.props.login_get());
          }
        })
        .catch((error) => this.setState({ loader: false }));
    }
  }

  focusField() {
    if (
      this.state.errors.firstname &&
      this.firstNameField &&
      this.firstNameField.current
    ) {
      this.firstNameField.current.focus();
    } else if (
      this.state.errors.lastname &&
      this.lastNameField &&
      this.lastNameField.current
    ) {
      this.lastNameField.current.focus();
    } else if (
      this.state.errors.address &&
      this.addressField &&
      this.addressField.current
    ) {
      this.addressField.current.focus();
    } else if (
      this.state.errors.zip &&
      this.zipcodeField &&
      this.zipcodeField.current
    ) {
      this.zipcodeField.current.focus();
    }
  }

  renderVerificationQuestions() {
    const question =
      this.state.verification_questions[
        this.state.verification_quesitons_counter
      ];
    const enableSubmit =
      utils.is_obj_empty(this.state.errors) &&
      this.state.current_verification_answer;
    const { t } = this.props;
    return (
      <>
        <div className="mb-3">
          {t(
            "Please answer the following questions to the best of your ability.",
          )}
        </div>
        <Form
          noValidate
          onSubmit={(e) =>
            enableSubmit
              ? this.submitVerificationQuestion(
                  e,
                  question.type[0],
                  this.state.current_verification_answer,
                )
              : e.preventDefault()
          }
          className="verification-question"
        >
          <div className="mb-3">
            {" "}
            <p className="font-weight-bold" style={{ fontSize: "18px" }}>
              {question.prompt[0]}
            </p>{" "}
          </div>
          {question.answer.map((ans, i) => (
            <div className="input-wrapper" key={i}>
              <input
                checked={this.state.current_verification_answer === ans}
                type="radio"
                id={`ans-${ans.replace(/\s/g, "-")}`}
                name={question.prompt[0]}
                value={ans}
                onChange={(e) => this.handleAnsweringVerification(e)}
              />
              <label htmlFor={`ans-${ans.replace(/\s/g, "-")}`}>{ans}</label>
            </div>
          ))}
          <br />
          <br />
          <div className="unauth-body-footer" style={{ marginTop: "15px" }}>
            <Button
              variant={
                utils.is_obj_empty(this.state.errors) &&
                this.state.current_verification_answer
                  ? "primary"
                  : "disable"
              }
              type="submit"
              block
            >
              {" "}
              {t("Continue")}{" "}
            </Button>
            <div className="text-center">
              <Form.Text className="text-danger form-error" aria-live="polite">
                {this.state.errors.system ? t(this.state.errors.system) : ""}
              </Form.Text>
            </div>
          </div>
        </Form>
      </>
    );
  }

  renderFailPage() {
    utils.clear_local_storage();
    return (
      <div>
        <Trans i18nKey="verificationSupportText">
          Sorry, we could not verify your details. <br /> Please contact{" "}
          <a href={LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">
            support
          </a>{" "}
          for assistance.
        </Trans>
        <div className="unauth-body-footer" style={{ marginTop: "15px" }}>
          <Button href={LINKS.SUPPORT} variant="primary" block>
            {this.props.t("Contact support")}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { showFailPage, verification_questions: verificationQuestions } =
      this.state;

    if (showFailPage) {
      return <div className="verification">{this.renderFailPage()}</div>;
    }

    return (
      <div className="verification">
        {verificationQuestions
          ? this.renderVerificationQuestions()
          : this.renderVerificationForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  language: state.app.language,
});

export default withRouter(
  connect(mapStateToProps, {
    verify_post,
    submit_verification_post,
    update_user_put,
    login_get,
    update_registration_flow,
  })(
    withTranslation("fields")(
      withService(["userService", "validationService"])(Verification),
    ),
  ),
);
