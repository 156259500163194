import mapboxgl from "mapbox-gl";
import { useRef, useState } from "react";
import { mapboxConfig } from "./constants/api_config";
import styles from "./TestMap.module.css";

mapboxgl.accessToken = mapboxConfig.accessToken;
const MAPBOX_STYLE = mapboxConfig.styleUrl;
const DEFAULT_ZOOM = 1;

const regionNames = [
  "L1.1",
  "L1.2",
  "L1.3",
  "L1.4",
  "L1.5",
  "L1.6",
  "L2.1",
  "L2.2",
  "L2.3",
  "L2.4",
  "L2.5",
  "L2.6",
  "L2.7",
  "L2.8",
  "L2.9",
  "L2.10",
  "L2.11",
  "L2.12",
  "L2.13",
  "L2.14",
  "L2.15",
  "L2.16",
  "L2.17",
  "L2.18",
  "L2.19",
  "L2.20",
  "L2.21",
  "L2.22",
  "L2.23",
  "L2.24",
  "L2.25",
  "L2.26",
  "L2.27",
  "L2.28",
  "L2.29",
  "L2.30",
  "L2.31",
  "L2.32",
  "L3.1",
  "L3.2",
  "L3.3",
  "L3.4",
  "L3.5",
  "L3.6",
  "L3.7",
  "L3.8",
  "L3.9",
  "L3.10",
  "L3.11",
  "L3.12",
  "L3.13",
  "L3.14",
  "L3.15",
  "L3.16",
  "L3.17",
  "L3.18",
  "L3.19",
  "L3.20",
  "L3.21",
  "L3.22",
  "L3.23",
  "L3.24",
  "L3.25",
  "L3.26",
  "L3.27",
  "L3.28",
  "L3.29",
  "L3.30",
  "L3.31",
  "L3.32",
  "L3.33",
  "L3.34",
  "L3.35",
  "L3.36",
  "L3.37",
  "L3.38",
  "L3.39",
  "L3.40",
  "L3.41",
  "L3.42",
  "L3.43",
  "L3.44",
  "L3.45",
  "L3.46",
  "L3.47",
  "L3.48",
  "L3.49",
  "L3.50",
  "L3.51",
  "L3.52",
  "L3.53",
  "L3.54",
  "L3.55",
  "L3.56",
  "L3.57",
  "L3.58",
  "L3.59",
  "L3.60",
  "L3.61",
  "L3.62",
  "L3.63",
  "L3.64",
  "L3.65",
  "L3.66",
  "L3.67",
  "L3.68",
  "L3.69",
  "L3.70",
  "L3.71",
  "L3.72",
  "L3.73",
  "L3.74",
  "L3.75",
  "L3.76",
  "L3.77",
  "L3.78",
  "L3.79",
  "L3.80",
  "L3.81",
  "L3.82",
  "L3.83",
  "L3.84",
  "L3.85",
  "L3.86",
  "L3.87",
  "L3.88",
  "L3.89",
  "L3.90",
  "L3.91",
  "L3.92",
  "L3.93",
  "L3.94",
  "L3.95",
  "L3.96",
  "L3.97",
  "L3.98",
  "L3.99",
  "L3.100",
  "L3.101",
  "L3.102",
  "L3.103",
  "L3.104",
  "L3.105",
  "L3.106",
  "L3.107",
  "L3.108",
  "L3.109",
  "L3.110",
  "L3.111",
  "L3.112",
  "L3.113",
  "L3.114",
  "L3.115",
  "L3.116",
  "L3.117",
  "L3.118",
];

const colors = {};

export function TestMap() {
  const mapContainerRef = useRef();
  const [map, setMap] = useState();
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [opacityL1, setOpacityL1] = useState(0.8);
  const [opacityL2, setOpacityL2] = useState(0.8);
  const [opacityL3, setOpacityL3] = useState(0.8);

  const initMap = () => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: MAPBOX_STYLE,
      zoom: 1,
      minZoom: 1,
      maxZoom: 10,
      dragRotate: false,
    });
    setMap(map);
  };

  const loadRegion = async (regionName) => {
    if (map.getSource(regionName)) {
      // don't add if it already exists
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/ancestry/region/geometry?level_region_code=${regionName}`,
    );
    if (!res.ok) {
      console.log("error downloading region");
      return;
    }
    const region = await res.json();
    let featureCollection = region.data;

    // Split geometries
    let newFeatures = [];
    featureCollection.features.forEach((feature) => {
      feature.geometry.coordinates.forEach((coordinate) => {
        newFeatures.push({
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: [coordinate],
          },
        });
      });
    });
    featureCollection.features = newFeatures;

    colors[regionName] = featureCollection.features[0].properties.fill;

    map.addSource(regionName, {
      type: "geojson",
      data: featureCollection,
    });
  };

  const addRegion = (regionName, opacity = 0.8) => {
    if (map.getLayer(regionName)) {
      map.removeLayer(regionName);
    }
    // console.log(colors);
    map.addLayer({
      id: regionName,
      type: "fill",
      source: regionName,
      paint: {
        "fill-color": colors[regionName],
        "fill-opacity": Number(opacity),
        // "fill-outline-color": "hsla(100, 0%, 0%, 0.5)",
        // "fill-outline-color": "hsla(100, 0%, 100%, 0.3)",
        "fill-outline-color": "#fff",
      },
    });

    // map.moveLayer(regionName, "country-label");
    map.moveLayer(regionName, "state-label");
  };

  const removeRegion = (regionName) => {
    if (map.getLayer(regionName)) {
      map.removeLayer(regionName);
    }
  };

  const loadRegions = async () => {
    setLoadingRegions(true);
    const settled = await Promise.allSettled(
      regionNames.map(async (name) => {
        return loadRegion(name);
      }),
    );
    setLoadingRegions(false);
    return settled;
  };

  const showRegions = async (level, opacity) => {
    const regionsToShow = level
      ? regionNames.filter((n) => n.slice(0, 2) === level)
      : regionNames;
    // console.log(regionsToShow);
    return Promise.allSettled(
      regionsToShow.map(async (name) => {
        return addRegion(name, opacity);
      }),
    );
  };

  const removeRegions = async () => {
    return Promise.allSettled(
      regionNames.map(async (name) => {
        return removeRegion(name);
      }),
    );
  };

  // const doEverything = async () => {
  //   console.log("loading regions...");
  //   await loadRegions();
  //   console.log("showing regions...");
  //   await showRegions();
  //   console.log("done");
  // };

  // <button onClick={() => loadRegion("L1.1")}>Load region</button>
  // <button onClick={() => addRegion("L1.1")}>Add region</button>
  // <button onClick={() => removeRegion("L1.1")}>Remove region</button>
  // <button onClick={doEverything}>Do eveything</button>

  return (
    <div>
      <div ref={mapContainerRef} className={styles.mapContainer} />
      <div className={styles.info}>
        <button onClick={initMap}>Init</button>
        <button onClick={loadRegions}>
          {loadingRegions ? "Loading..." : "Load Regions"}
        </button>
        <button onClick={() => showRegions()}>Show All Regions</button>
        <button onClick={() => showRegions("L1", opacityL1)}>
          Show Regions L1
        </button>
        <button onClick={() => showRegions("L2", opacityL2)}>
          Show Regions L2
        </button>
        <button onClick={() => showRegions("L3", opacityL3)}>
          Show Regions L3
        </button>
        <button onClick={removeRegions}>Remove regions</button>
        <br />
        <label>
          Opacity L1{" "}
          <input
            type="text"
            value={opacityL1}
            onChange={(e) => {
              setOpacityL1(e.target.value);
            }}
          />
        </label>
        <label>
          Opacity L2{" "}
          <input
            type="text"
            value={opacityL2}
            onChange={(e) => {
              setOpacityL2(e.target.value);
            }}
          />
        </label>
        <label>
          Opacity L3{" "}
          <input
            type="text"
            value={opacityL3}
            onChange={(e) => {
              setOpacityL3(e.target.value);
            }}
          />
        </label>
      </div>
    </div>
  );
}
