import React from "react";
import { useTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import classnames from "classnames/bind";

import { useInjection } from "../../../services/ServiceProvider";
import { UserService } from "../../../services/UserService";
import {
  GenericWorkflow,
  GenericWorkflowStep,
} from "../../../services/types/WorkflowTypes";

import { selectCurrentStep } from "../../../store/Workflow/selector";

import AuthHeader from "../../global/auth_header";

import styles from "./WorkflowHeader.module.scss";

import WorkflowProgressComponent from "../../WorkflowNext/WorkflowProgress/WorkflowProgressComponent";

const cx = classnames.bind(styles);

type WorkflowHeaderStateProps = {
  currentStep: GenericWorkflowStep;
};

type WorkflowHeaderComponentProps = {
  workflowName: string | null;
  workflow: GenericWorkflow | null;
  isWorkflowLoading: boolean;
} & WorkflowHeaderStateProps;

function WorkflowHeaderComponent({
  workflowName,
  workflow,
  isWorkflowLoading,
  currentStep,
}: WorkflowHeaderComponentProps) {
  const { t } = useTranslation();
  const headerTitle = t(workflowName || "unknown");

  const userService: UserService = useInjection("userService");
  const user = userService.getCurrentUser();

  const currentStepMock = {
    component: "text_media",
    name: "Welcome to EMR",
    details: {
      subtitle: "EMR intro subtitle",
      description: "EMR intro description",
      allow_pause: "true",
    },
    body: [
      {
        title: "About EMR",
        text: "<ul><li>something</li><li>list</li></ul>",
      },
    ],
    step: "EMR_Intro",
    exit_attribute: ["temp_action_emr_seen|true"],
    entry_attribute: ["workflow_emr_start|true"],
    id: "4b62db51-0938-7f18-41a4-73e846fff085",
  };

  return (
    <div className={styles.workflowHeader}>
      <AuthHeader user={user}>
        <div className={styles.workflowHeaderContent}>
          <div className={styles.workflowTitle}>
            <h1 className="col-lg-5">{headerTitle}</h1>
          </div>
          <div
            className={cx(
              styles.workflowHeaderProgress,
              "d-flex justify-content-center",
            )}
          >
            <div className={cx(styles.workflowHeaderProgressContainer)}>
              <WorkflowProgressComponent
                workflow={workflow}
                currentStep={currentStep || currentStepMock}
                isWorkflowLoading={isWorkflowLoading}
              />
            </div>
          </div>
        </div>
      </AuthHeader>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<any, WorkflowHeaderStateProps>(
  {
    currentStep: selectCurrentStep,
  },
);

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowHeaderComponent);
