import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import SetPasswordForm from "../password/set_password_form";
import EditEmailForm from "./edit_profile_form";
import { EDIT_PERSONAL_MODAL } from "../../constants/editPersonalModal";
import { utils } from "../../utils/utils_general";
import { FEATURE } from "../../constants/features";
import { logout_get } from "../../actions/logoutAction";
import PAGES from "../../constants/pages";
// import { findAllByLabelText } from '@testing-library/react';

class PersonalEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      completed: null,
      isLockedAccount: false,
    };
  }

  onCompleted() {
    this.setState({ completed: true });
  }

  closeModal() {
    this.setState({ completed: null });
    return this.props.onHide();
  }

  handleRequestLogout = () => {
    this.props
      .logout_get()
      .then(() => this.props.history.push(PAGES.LOGIN))
      .catch(() => {
        // Just close the modal if logout fails
        this.setState({ isLockedAccount: false });
        this.closeModal();
      });
  };

  handleLockedAccount = () => {
    this.setState({ isLockedAccount: true });
  };

  renderForm() {
    const is2FAEnabled =
      this.props.is2FAEnabled &&
      this.props.featureFlag &&
      this.props.featureFlag[FEATURE.MFA];
    if (this.props.show === EDIT_PERSONAL_MODAL.PASSWORD) {
      return (
        <SetPasswordForm
          onSuccess={() => this.onCompleted()}
          onRequestLogout={() => this.handleRequestLogout()}
          onLockedAccount={() => this.handleLockedAccount()}
          isLockedAccount={this.state.isLockedAccount}
          user={this.props.loginUser}
          is2FAEnabled={is2FAEnabled}
          closeModal={() => this.closeModal()}
          page="profile"
        />
      );
    }
    return (
      <EditEmailForm
        onCompleted={() => this.onCompleted()}
        is2FAEnabled={is2FAEnabled}
        type={this.props.show}
      />
    );
  }

  renderCompleted(type) {
    return (
      <div>
        <p className="mb-4">
          {this.props.t("Your {{type}} has been successfully updated.", {
            type: this.renderTitle(type),
          })}
        </p>
        <Button
          variant="primary"
          type="submit"
          block
          onClick={this.closeModal.bind(this)}
        >
          {this.props.t("Close")}
        </Button>
      </div>
    );
  }

  renderTitle(type) {
    switch (type) {
      case EDIT_PERSONAL_MODAL.EMAIL:
        return this.props.t("email");
      case EDIT_PERSONAL_MODAL.HOME_PHONE:
        return this.props.t("home phone");
      case EDIT_PERSONAL_MODAL.MOBILE_PHONE:
        return this.props.t("mobile phone");
      default:
        return type;
    }
  }

  render() {
    const is2FAEnabled =
      this.props.is2FAEnabled &&
      this.props.featureFlag &&
      this.props.featureFlag[FEATURE.MFA];
    const browser = utils.get_browser();
    if (!this.props.show) {
      /// need this or else something weird happening onhide modal
      return null;
    }

    return (
      <Modal
        show={Boolean(this.props.show)}
        onHide={
          this.state.isLockedAccount
            ? this.handleRequestLogout
            : this.closeModal.bind(this)
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={
          (is2FAEnabled ? "two-factor-modal " : "non-two-factor-modal ") +
          browser
        }
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.t("Edit")} {this.renderTitle(this.props.show)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`modal-body-${this.props.show.replace(/ /g, "-")}`}
        >
          {this.state.completed
            ? this.renderCompleted(this.props.show)
            : this.renderForm()}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { logout_get })(
  withTranslation()(PersonalEditModal),
);
