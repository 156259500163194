/**
 * @file components/password/reset_password_complete.js
 */

import React from "react";
import { Button } from "react-bootstrap";
import PAGES from "../../constants/pages";
import { withTranslation } from "react-i18next";

const ResetPasswordComplete = (props) => (
  <section className="reset-password-email-sent">
    <div className="unauth-body-wrapper">
      <h1>{props.t("Forgot Password")}</h1>
      <div>
        {props.t("Your new password has been set.")}
        <br />
        {props.t("You may go ahead and log in now.")}
      </div>
      <div>
        <Button
          href={PAGES.LOGIN}
          variant="primary"
          type="submit"
          block
          className="mt-3"
        >
          {props.t("Login")}
        </Button>
      </div>
    </div>
  </section>
);

export default withTranslation()(ResetPasswordComplete);
