import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { logout_get } from "../../actions/logoutAction";
import PAGES from "../../constants/pages";
import { UNEXPECTED_ERROR } from "../../constants/errors";

class Logout extends React.Component {
  handleLogout(e) {
    e.preventDefault();
    this.props
      .logout_get()
      .then((response) => (window.location.href = PAGES.LOGIN))
      .catch((error) =>
        this.setState({ errors: { system: UNEXPECTED_ERROR } }),
      );
  }

  render() {
    return (
      <Button className="btn-logout" onClick={(e) => this.handleLogout(e)}>
        {this.props.t("Sign out")}
      </Button>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, { logout_get })(withTranslation()(Logout)),
);
