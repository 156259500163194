import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./SelectableField.module.scss";
import classnames from "classnames/bind";
import { ReactComponent as SubtractIcon } from "../../../assets/icons/subtract.svg";

const cx = classnames.bind(styles);

export type SelectableFieldProps = {
  id?: string;
  label?: string;
  value: any;
  onOptionClick?: (e: React.MouseEvent, value: any) => void;
  onOptionRemoveClick?: (e: React.MouseEvent, value: any) => void;
};

const isTextNotCode = (text?: string) =>
  text && !text.includes(": ") && !text.includes("_");

export default function SelectableFieldComponent({
  id = "",
  label,
  value,
  onOptionClick = () => {},
  onOptionRemoveClick = () => {},
}: SelectableFieldProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.questionAnswerItem)}
      onClick={(e) => onOptionClick(e, value)}
    >
      <Form.Label className={cx(styles.label)}>
        {label && isTextNotCode(label) ? label : t(`answers_text_${id}`)}
      </Form.Label>
      <button
        type="button"
        className={cx(styles.removeButton)}
        onClick={(e) => onOptionRemoveClick(e, value)}
      >
        <SubtractIcon />
      </button>
    </div>
  );
}
