/**
 * @file components/password/reset_password_update.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { utils } from "../../utils/utils_general";
import { RESET_PWD_ERROR_DISPLAY, RESPONSE_CODE } from "../../constants/errors";

import PAGES from "../../constants/pages";
import { login_post } from "../../actions/loginAction";
import { get_user_attributes_get } from "../../actions/userAction";
import SetPasswordForm from "./set_password_form";

class ResetPasswordUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
      password: "",
      passwordFormatErrors: null,
      errors: {},
      user: null,
      is2FAEnabled: false,
    };
  }

  componentDidMount() {
    const token = utils.get_url_param(this.props.location.search, "token");
    if (token) {
      this.props
        .login_post({ token }, true)
        .then((response) => {
          if (response.token_type !== "password_reset") {
            this.setState({
              errors: { system: RESET_PWD_ERROR_DISPLAY.TOKEN_INVALID },
            });
          } else if (new Date(response.expiry) < new Date()) {
            this.props.history.push(PAGES.RESET_PASSWORD_EXPIRED);
          } else {
            this.setState({ user: response });
            this.setState({
              is2FAEnabled: !!(response.mfa && response.mfa === "true"),
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status === RESPONSE_CODE["412_token_expired"] ||
              error.response.status === RESPONSE_CODE["401_unauthorized"])
          ) {
            this.props.history.push(PAGES.RESET_PASSWORD_EXPIRED);
          } else {
            this.setState({
              errors: { system: RESET_PWD_ERROR_DISPLAY.TOKEN_INVALID },
            });
          }
        });
    } else {
      this.props.history.push(PAGES.LOGIN);
    }
  }

  render() {
    return (
      <section className="reset-password reset-password-set-new">
        <div
          className={`unauth-body-wrapper ${this.state.is2FAEnabled ? "two-factor-modal" : null}`}
        >
          <h1>{this.props.t("Enter New Password")}</h1>
          <SetPasswordForm
            onSuccess={() =>
              this.props.history.push(PAGES.RESET_PASSWORD_COMPLETE)
            }
            user={this.state.user}
            is2FAEnabled={this.state.is2FAEnabled}
            closeModal={() => null}
            page="reset-password"
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, { login_post, get_user_attributes_get })(
    withTranslation()(ResetPasswordUpdate),
  ),
);
