import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PAGES from "../../constants/pages";
import CopyrightText from "../global/copyright_text";
import CobrandingLogo from "../global/cobranding_logo";

import styles from "./referral-landing-page.module.scss";

function Links() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <ul>
        <li>
          <a
            className="text-body"
            href={PAGES.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            // TODO: prefetch?
            // onMouseEnter={() => this.prefetchPrivacyPolicy()}
          >
            {t("Privacy Statement")}
          </a>
        </li>
        <li>
          <a
            className="text-body"
            href={PAGES.TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
            // TODO: prefetch?
            // onMouseEnter={() => this.prefetchTerms()}
          >
            {t("Terms & Conditions")}
          </a>
        </li>
      </ul>
    </div>
  );
}

export function ReferralLandingPageFooter() {
  return (
    <>
      <footer
        className={classNames(
          styles.footer,
          "m-0 d-flex d-md-none flex-column align-items-center",
        )}
      >
        <CobrandingLogo />
        <Links />
        <CopyrightText />
      </footer>
      <footer
        className={classNames(
          styles.footer,
          "m-0 d-none d-md-block container-fluid",
        )}
      >
        <div className="row align-items-center">
          <div className="col col-md-4 col-lg-3 text-left">
            <CopyrightText />
          </div>
          <div className="col col-md-5 col-lg-6 text-center">
            <Links />
          </div>
          <div className="col col-md-3 col-lg-3 text-right">
            <CobrandingLogo />
          </div>
        </div>
      </footer>
    </>
  );
}
