import { LOCAL_STORAGE } from "../constants/localStorage";
import { REDUX_ACTION } from "../constants/reduxAction";
import { utils } from "../utils/utils_general";

export function setBannerCount(count) {
  return (dispatch) => {
    utils.set_local_storage(LOCAL_STORAGE.CONSENT_RECOVERY_BANNER_COUNT, count);
    dispatch({
      type: REDUX_ACTION.CONSENT_RECOVERY_BANNER_COUNT,
      data: count,
    });
  };
}

export function getBannerCount() {
  return (dispatch) => {
    const count =
      utils.get_local_storage(LOCAL_STORAGE.CONSENT_RECOVERY_BANNER_COUNT) || 0;
    dispatch({
      type: REDUX_ACTION.CONSENT_RECOVERY_BANNER_COUNT,
      data: count,
    });
  };
}
