import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
// @ts-ignore
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import joi from "joi";
import ErrorText from "../../core/ErrorText/ErrorTextComponent";
import {
  FORM_FIELD_TYPES,
  InputTypes,
} from "../../../services/types/FreeFormTypes";

const defaultInputValidation = {
  [FORM_FIELD_TYPES.EMAIL]: (value: any) => {
    const validationSchema = joi.object({
      value: joi.string().email({ tlds: { allow: false } }),
    });
    return validationSchema.validate({ value });
  },
  [FORM_FIELD_TYPES.NUMBER]: (value: any) => {
    const validationSchema = joi.object({
      value: joi.number(),
    });
    return validationSchema.validate({ value });
  },
};

type InputComponentSettings = {
  ariaRequired?: boolean;
  required?: boolean;
  placeholder?: string;
};

export type InputComponentProps = {
  label?: string;
  name?: string;
  type?: InputTypes;
  value?: any;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg1: any) => void;
  // eslint-disable-next-line no-unused-vars
  onFocusChange?: (arg1: any) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (arg1: any) => void;
  validationError?: string;
  defaultValidation?: boolean;
  settings?: InputComponentSettings | {};
  rest?: any;
};

const InputComponent: React.FC<InputComponentProps> = ({
  label = "",
  name = "",
  type = FORM_FIELD_TYPES.TEXT,
  value,
  placeholder = "",
  settings = {},
  onChange,
  onFocusChange,
  validationError = "",
  onBlur,
  defaultValidation = true,
  ...rest
}: InputComponentProps) => {
  const fieldName = name || `dropdown-${uuid()}`;
  const [errorMsg, setErrorMsg] = useState("");
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    const newErrorMsg = validationError || "";
    setErrorMsg(newErrorMsg);
  }, [validationError]);

  const { t } = useTranslation();
  const haveError = false || Boolean(errorMsg);
  const inputType = type || FORM_FIELD_TYPES.TEXT;

  const translatedPlaceholder = t("Input Your Value" as const)?.toString();
  const placeholderValue = placeholder || translatedPlaceholder || undefined;
  const inputValue = currentValue || undefined;

  const handleChange = (e: React.ChangeEvent<any>): void => {
    setCurrentValue(e.target.value);

    if (typeof onChange === "function") {
      onChange(e);
    }
  };

  // eslint-disable-next-line consistent-return
  const validateCurrentValue = () => {
    if (!defaultValidation) {
      return null;
    }
    // @ts-ignore
    const validationRule: any = defaultInputValidation[inputType];
    if (inputType && validationRule && currentValue) {
      const { error } = validationRule(currentValue);
      if (error) {
        setErrorMsg(error?.toString());
      } else {
        setErrorMsg("");
      }
    }
  };

  const handleBlur = (e: React.ChangeEvent<any>): void => {
    validateCurrentValue();

    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  const handleFocus = (e: React.ChangeEvent<any>): void => {
    if (typeof onFocusChange === "function") {
      onFocusChange(e);
    }
  };

  return (
    <Form.Group controlId="formBasicEmail">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        onChange={(e) => handleChange(e)}
        name={fieldName}
        type={inputType}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputValue}
        placeholder={placeholderValue}
        {...settings}
        {...rest}
      />
      {haveError && <ErrorText errorMsg={errorMsg} />}
    </Form.Group>
  );
};

export default InputComponent;
