import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import AppLogo from "../../assets/images/app-logo.svg";
import LanguageSwitch from "./language_switch";
import { CONFIG, hasLanguageSwitcher } from "../../constants/config";
import PAGES from "../../constants/pages";

export class UnauthLandingHeader extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: 0,
    };
  }

  toggleMobileMenu() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="unauth-header">
        <div className="unauth-header-logo">
          <Link to="/">
            <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
          </Link>
        </div>
        <div
          className={`landing-header-menu-hamburger ${this.state.mobileMenuOpen ? "_open" : "_closed"}`}
          onClick={() => this.toggleMobileMenu()}
        >
          <span className="hamburger-strip" />
          <span className="hamburger-strip" />
          <span className="hamburger-strip" />
        </div>
        <div
          className={`unauth-header-landing-menu ${this.state.mobileMenuOpen ? "_open" : "_closed"}`}
        >
          <div className="landing-header-menu-links">
            <a href={PAGES.HOME_PAGE}>{t("Home")}</a>
            <a href="#section_2">{t("About")}</a>
            <a href="#section_3">{t("Why Now?")}</a>
            <a href="#section_4">{t("Contact")}</a>
          </div>
          {hasLanguageSwitcher ? (
            <div className="landing-header-menu-language">
              <LanguageSwitch />
            </div>
          ) : null}
          <div className="landing-header-menu-buttons">
            <a href={PAGES.LOGIN}>{t("Log In")}</a>
            <a href={PAGES.SIGNUP} className="_highlighted">
              {t("Sign Up")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UnauthLandingHeader);
