// expects a onComplete prop as this component will be used within workflow

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import {
  post_user_attributes_post,
  get_user_attributes_get,
} from "../../actions/userAction";
import { questionnaire_resp_post } from "../../actions/questionnaireAction";

import {
  get_emr_data,
  post_emr_connection,
  get_emr_connection,
  get_emr_workflow,
  post_emr_feedback,
} from "../../actions/emrAction";
import { ReactComponent as IconSearch } from "../../assets/icons/search.svg";
import PAGES from "../../constants/pages";
import { utils_workflow } from "../../utils/utils_workflow";
import EMRConnections from "./emr_results";
import EmptyResults from "./emr_empty_results";
import EMRListItem from "./emr_list_item";
import EMRModal from "./emr_modal";
import { Loading } from "../WorkflowNext/WorkflowSteps/Loading";
import PlaceholderComponent from "../core/Placeholder/PlaceholderComponent";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";
import { EMR_DISPLAY_ERRORS } from "../../constants/errors";
import { utils } from "../../utils/utils_general";

const DEFAULT_REDIRECT_DELAY = 5;

const EMR_STARTED_ATTR = "emr_started";

class EMR extends React.Component {
  constructor() {
    super();
    this.searchInput = React.createRef();
    this.state = {
      loading: false,
      modalShown: false,
      modalError: null,
      modalItem: null,
      pickedItem: null,
      modalTimeout: null,
      modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      searchSubmitted: false,
      emrDataLoading: false,
      emrData: [],
      userConfirmed: false,
      userConnected: false,
      showThankYou: false,
      searchError: null,
      emrTab: null,
      emrConnections: [],
      changedMyMind: false,
      userRejected: false,
      showEmptyResults: false,
    };
  }

  async componentDidMount() {
    await this.loadInitialData();
    const hashMatch = this.props.location.hash.match(/#clinic=(.+)/);
    if (hashMatch) {
      // TODO: don't know if we are using this
      const clinicResourceUrl = hashMatch[1];
      const matchingClinic = this.props.emrConnections.find(
        (c) => c.resource_url === clinicResourceUrl,
      );
      if (matchingClinic) {
        this.openEhrModal(matchingClinic);
      }
    }
    this.props.post_user_attributes_post(EMR_STARTED_ATTR, true, true);
  }

  async loadInitialData() {
    this.setState({ loading: true });
    await Promise.all([
      this.getEmrConnections(),
      this.props.get_user_attributes_get(true),
    ]);
    this.setState({
      userConfirmed: this.props.userAttribute.emr_confirmed,
      loading: false,
    });
  }

  exitStep() {
    const { isWorkflowNext, history } = this.props;
    if (!isWorkflowNext) {
      history.push(PAGES.DASHBOARD);
    }
  }

  async workflowExit() {
    const { onWorkflowExit } = this.props;

    if (typeof onWorkflowExit === "function") {
      await onWorkflowExit();
    }
    this.exitStep();
  }

  async handleSkip() {
    const {
      onComplete,
      post_user_attributes_post,
      onWorkflowSkip,
      isWorkflowNext,
    } = this.props;

    const attr = "temp_emr_seen";
    await post_user_attributes_post(attr, true);

    await onComplete();

    if (isWorkflowNext && typeof onWorkflowSkip === "function") {
      onWorkflowSkip();
      this.exitStep();
      return null;
    }

    this.workflowExit();
  }

  getEmrConnections() {
    return this.props.get_emr_connection().then((res) => {
      if (res.length > 0) {
        this.setState({ userConfirmed: true, emrConnections: res });
      }
    });
  }

  is_valid_value_length(value) {
    let is_valid_value = true;
    if (!value.trim() || value.trim().length < 2) {
      this.setState({
        emrDataLoading: false,
        searchError: EMR_DISPLAY_ERRORS.EMR_INVALID_INPUT,
        searchInputError: EMR_DISPLAY_ERRORS.EMR_INVALID_INPUT,
      });
      is_valid_value = false;
    }
    return is_valid_value;
  }

  async handleSearchSubmit(e) {
    e.preventDefault();
    const { value = "" } = this.searchInput.current;
    // validate is greater than 2
    if (!this.is_valid_value_length(value)) return;

    const clean_value = utils.cleanSpecialCharacter(value);
    // validate no special characters
    if (value.length > clean_value.length) {
      this.setState({
        emrDataLoading: false,
        searchError: EMR_DISPLAY_ERRORS.EMR_INVALID_CHARACTER_INPUT,
        searchInputError: EMR_DISPLAY_ERRORS.EMR_INVALID_CHARACTER_INPUT,
      });
      return;
    }

    if (!this.is_valid_value_length(clean_value)) return;

    this.setState({
      emrDataLoading: true,
      searchSubmitted: true,
      searchError: null,
      searchInputError: null,
    });

    try {
      const res = await this.props.get_emr_data(clean_value.trim(), true);
      if (!res.data.length) {
        this.props
          .post_emr_feedback(
            {
              search_terms: clean_value.trim(),
              search_type: "healthsystem",
            },
            true,
          )
          .catch(console.error);
        this.setState({ showEmptyResults: true });
      }
      this.setState({
        emrDataLoading: false,
        emrData: res.data,
      });
    } catch (err) {
      this.setState({
        searchError: err,
        emrDataLoading: false,
      });
    }
  }

  setStepExitAttributes(step) {
    let exitAttributeRequests = [];
    if (step !== undefined) {
      const { post_user_attributes_post } = this.props;
      const exitAttributes = utils_workflow.getPostAttributes(
        step.exit_attribute,
      );
      exitAttributeRequests = Object.entries(exitAttributes).map(([key]) =>
        post_user_attributes_post(key, true),
      );
    }
    return Promise.all(exitAttributeRequests);
  }

  async confirmEmrSend(confirm = true) {
    const code = confirm ? "emr_confirm_yes" : "emr_confirm_no";
    await this.props.questionnaire_resp_post("emr", [
      {
        question: "emr_confirm",
        answers: [code],
      },
    ]);
    this.setState({ userConfirmed: confirm });
    if (!confirm) {
      if (this.props.onWorkflowSkip) {
        await this.props.onWorkflowSkip();
      } else {
        await this.setStepExitAttributes(this.props.step);
        await this.onFormComplete();
      }
      return this.props.history.push(PAGES.DASHBOARD);
    }
  }

  openEhrModal(item) {
    this.setState({
      modalShown: true,
      modalItem: item,
      modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      userConnected: false,
    });
  }

  closeEhrModal(showThankYou = false, clearSearch = false, rejected = false) {
    window.clearTimeout(this.state.modalTimeout);
    this.setState({
      modalShown: false,
      modalItem: null,
      modalError: null,
      modalTimeout: null,
      userConnected: false,
      modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      showThankYou,
      emrData: clearSearch ? [] : this.state.emrData,
      searchSubmitted: !clearSearch,
    });
  }

  onConnectionError(err) {
    this.setState({
      userRejected: err,
    });
  }

  getContent() {
    if (this.state.showThankYou) return this.renderThankYouCard();
    return !this.state.userConfirmed
      ? this.renderConfirmation()
      : this.renderSearch();
  }

  async handleThankyouCardFormComplete() {
    await this.setStepExitAttributes(this.props.step);
    this.onFormComplete();
  }

  async onFormComplete() {
    const { onComplete, post_user_attributes_post } = this.props;

    // TODO: maybe find a better way to do this...
    // NOTE: I am not sure if this is still required
    const attr = "temp_emr_seen";
    await post_user_attributes_post(attr, true);
    await onComplete();
    this.workflowExit();
  }

  renderThankYouCard() {
    return (
      <>
        <div className="workflow-section workflow-banner light p-4 rounded emr-thank-you mb-5">
          <div className="workflow-banner-flex-wrapper">
            <div className="workflow-banner-right">
              <div className="workflow-banner-icon">
                <div className="checkmark" />
              </div>
            </div>
            <div className="workflow-banner-left">
              <div className="workflow-banner-content mb-5 ">
                <h2>
                  {this.props.t(
                    "Thank you for connecting EHR we may ask you in 30 days to re-connect. Help scientists stay up to date on your health.",
                  )}
                </h2>
              </div>
              <div className="workflow-banner-button">
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={() => this.closeEhrModal(false, true)}
                >
                  {this.props.t("Connect Another Health System")}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => this.handleThankyouCardFormComplete()}
                >
                  {this.props.t("I'm Done!")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderConfirmation() {
    return (
      <div className="emr-wrapper">
        <div className="row">
          <div className="col-12 col-sm-12 pb-4">
            <HtmlContentComponent
              markup={this.props.t(
                "It seems you have not previously agreed to connect your EHR record. You may agree now or skip this request.",
              )}
            />
          </div>
        </div>
        <div className="emr-confirm-buttons">
          <Button variant="primary" onClick={() => this.confirmEmrSend(true)}>
            {this.props.t("Agree to connect EHR")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.confirmEmrSend(false)}
          >
            {this.props.t("I do not agree, skip this")}
          </Button>
        </div>
      </div>
    );
  }

  renderSearchResults(emrData = []) {
    const emrDataWithConnected = emrData.map((item) => {
      this.props.emrConnections.forEach((existingItem) => {
        if (existingItem.id === item.id) {
          item.connected = true;
        }
      });
      return item;
    });
    return (
      <div className="emr-search-results">
        {this.state.searchSubmitted && (
          <div className="emr-search-results-count mb-1">
            {this.props.t("{{count}} results", { count: emrData.length })}
          </div>
        )}
        <div className="emr-search-container">
          {emrDataWithConnected.map((item, key) => (
            <EMRListItem
              key={key}
              connectCopy={this.props.t(
                item.connected ? "Re-connect" : "Connect",
              )}
              connectOnClick={() => this.openEhrModal(item)}
              item={item}
            />
          ))}
        </div>
      </div>
    );
  }

  renderSearch() {
    return (
      <>
        <div className="emr-wrapper">
          <Form
            className="emr-search-form"
            onSubmit={(e) => this.handleSearchSubmit(e)}
          >
            <h3 className="pb-4">
              {this.props.t("Please search for your health institution")}
            </h3>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                ref={this.searchInput}
                placeholder={this.props.t("Enter health institution name")}
              />
              <InputGroup.Append>
                <Button
                  disabled={this.state.emrDataLoading}
                  variant="primary"
                  type="submit"
                >
                  <IconSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
          {this.state.searchError &&
            (this.state.searchInputError ? (
              <p className="text-danger">
                {this.props.t(this.state.searchError)}
              </p>
            ) : (
              <p className="text-danger">
                {this.props.t("Something went wrong. Please try again.")}
              </p>
            ))}
          {!this.state.searchError && this.state.emrDataLoading && <Loading />}
          {this.state.searchSubmitted &&
            !this.state.searchError &&
            !this.state.emrDataLoading &&
            !this.state.showThankYou &&
            this.renderSearchResults(this.state.emrData)}
        </div>
      </>
    );
  }

  renderEmrResults() {
    return this.state.userConfirmed && this.props.emrConnections.length > 0 ? (
      <EMRConnections
        emrConnections={this.props.emrConnections}
        onConnectClick={(item) => this.openEhrModal(item)}
      />
    ) : null;
  }

  renderEmptyResults() {
    return (
      <EmptyResults
        searchType="healthsystem"
        onSkip={() => this.handleSkip()}
        onFeedbackSubmit={(feedback) =>
          this.props.post_emr_feedback({
            ...feedback,
            search_terms: this.searchInput.current?.value || "",
          })
        }
        onFinished={() => {
          this.setState({ showEmptyResults: false });
          this.searchInput.current.value = "";
          this.searchInput.current.focus();
        }}
        show={this.state.showEmptyResults}
      />
    );
  }

  render() {
    if (!this.props.userAttribute || this.state.loading) {
      return (
        <div>
          <h3 className="pb-4 col-8 pl-0">
            <PlaceholderComponent />
          </h3>
          <div className="mb-3">
            <PlaceholderComponent height={2} />
          </div>
          <div className="text-right">
            <PlaceholderComponent inline height={2} width={34} />
          </div>
        </div>
      );
    }

    const showSkipButton = !this.state.showThankYou;
    const showDisagreeButton =
      this.state.userRejected ||
      (this.state.changedMyMind && !this.state.showThankYou);
    const showButton = showSkipButton || showDisagreeButton;
    return (
      <>
        <EMRModal
          show={this.state.modalShown}
          modalItem={this.state.modalItem}
          onConnectionComplete={(rejected) =>
            this.closeEhrModal(true, true, rejected)
          }
          onConnectionError={(err) => this.onConnectionError(err)}
          onCloseClick={(res) => this.closeEhrModal(false, false, res)}
        />

        {/* search results, or confirm emr (if not confirmed) */}
        {this.getContent()}

        {this.renderEmptyResults()}

        {/* connected systems */}
        {this.renderEmrResults()}

        {showButton ? (
          <div className="emr-skip-buttons mt-5">
            {showSkipButton ? (
              <div>
                <Button
                  variant="secondary"
                  className="mb-2"
                  onClick={() => this.handleSkip()}
                >
                  {this.props.t("I'd like to skip this step for now")}
                </Button>
              </div>
            ) : null}
            {showDisagreeButton ? (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => this.confirmEmrSend(false)}
                >
                  {this.props.t(
                    "I’ve changed my mind. I do not agree to share my EMR record",
                  )}
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  userAttribute: state.userAttribute,
  emrConnections: state.emrConnections.data,
  ...ownProps,
});

const mapDispatchToProps = {
  get_emr_data,
  post_emr_connection,
  post_emr_feedback,
  get_emr_connection,
  get_emr_workflow,
  post_user_attributes_post,
  questionnaire_resp_post,
  get_user_attributes_get,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EMR)),
);
