import { useMemo } from "react";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { utils } from "../utils/utils_general";
import { useInjection } from "./ServiceProvider";
import { UserData } from "./types/UserTypes";
import { IUserService } from "./UserService";

function useCurrentUser(): UserData | null {
  const token = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
  const userService = useInjection<IUserService>("userService");
  const currentUser = useMemo(
    () => userService.getCurrentUser(),
    [token, userService],
  );

  return currentUser;
}

export { useCurrentUser };
