import { GenericWorkflow } from "../services/types/WorkflowTypes";

// TODO: RGC-2218: Remove this once the actual workflow exists.
const workflow_order_dna_kit: GenericWorkflow = [
  {
    component: "text_media",
    name: "Welcome to DNA Kit",
    details: {
      //subtitle: "EMR intro subtitle",
      description:
        "Everything that is about DNA Kit goes here. Brief intro about DNA Kit",
      allow_pause: "true",
    },
    body: [
      {
        title: "DNA Collection Kits",
        text:
          "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce risus urna, tristique vitae nunc nec, efficitur mollis elit. Donec aliquam eget sapien in cursus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec at commodo eros, vitae elementum dui. Sed rutrum dapibus arcu vitae sodales. Quisque cursus, dolor et tempor condimentum, nibh neque egestas nibh, nec ornare turpis erat non lectus. Vivamus lacus sem, dictum eget nisi at, hendrerit convallis leo. In in metus congue, auctor dolor ut, commodo purus. Pellentesque eu suscipit turpis. Quisque sed est tempus, auctor urna non, pulvinar libero. Praesent ultricies congue suscipit. Phasellus feugiat velit nec pretium tempor. Vivamus vitae tempor augue.</p>" +
          "<p>Cras sollicitudin nunc ut tortor pretium, non lobortis ligula mollis. Etiam hendrerit justo id laoreet iaculis. Donec in arcu nisi. Nulla viverra maximus lacus sit amet rhoncus. Vivamus vel neque tempor, sagittis ligula egestas, semper risus. Vivamus semper nunc nec rutrum interdum. Sed tempus tristique dapibus. Fusce quis odio finibus, luctus leo sit amet, varius enim.</p>",
      },
    ],
    step: "DNA_Order_Intro",
    exit_attribute: ["*"],
    entry_attribute: ["workflow_emr_started|true"],
    id: "b54fad1e-005d-b5bb-7ebd-80a7a9888c1e",
  },
  {
    component: "order_dna_kit",
    name: "Order DNA Kit",
    step: "DNA_Order_Form",
    details: {
      //subtitle: "EMR intro subtitle",
      description:
        "Everything that is about DNA Kit goes here. Brief intro about DNA Kit",
      allow_pause: "true",
    },
    exit_attribute: ["*"],
    entry_attribute: ["workflow_emr_started|true"],
    id: "b54fad1e-005d-b5bb-7ebd-80a7a9888c1g",
  },
];

const sampleWorkflows = {
  workflow_order_dna_kit,
};

export default sampleWorkflows;
