import { withRouter } from "react-router-dom";
import ConfirmEmailLogin from "../confirm-email/confirm_email_login";

const ConfirmEmailAutoLogin = () => {
  return (
    <section className={`signup-component confirm-email`}>
      <div className="signup-body-wrapper">
        <ConfirmEmailLogin />
      </div>
    </section>
  );
};

export default withRouter(ConfirmEmailAutoLogin);
