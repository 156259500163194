import { Action } from "redux";
import { DnaKitOrderData } from "../../services/types/DnaKit";
import { OrderDnaKitInterface } from "../../services/types/validations/OrderDnaKit";
import { DNA_KIT_REDUCER_NAMESPACE } from "./Constants";

const types = {
  SET_DNA_KIT_ORDER_PAYLOAD: `${DNA_KIT_REDUCER_NAMESPACE}.doSetDnaKitOrderPayload`,
  SET_DNA_KIT_ORDER_REQUESTING: `${DNA_KIT_REDUCER_NAMESPACE}.doSetDnaKitOrderRequesting`,
  SET_DNA_KIT_CREATED_ORDER: `${DNA_KIT_REDUCER_NAMESPACE}.doSetDnaKitCreatedOrder`,
  SET_DNA_KIT_ORDER_ERRORS: `${DNA_KIT_REDUCER_NAMESPACE}.doSetDnaKitOrderErrors`,
};

export type DnaKitDataTypes = OrderDnaKitInterface | DnaKitOrderData | Error[];

export interface DnaKitAction extends Action<string> {
  data?: DnaKitDataTypes;
}

export default types;
