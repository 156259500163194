import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PAGES from "../../../constants/pages";
import {
  get_user_attributes_get,
  post_workflow_complete_post,
} from "../../../actions/userAction";
import {
  registration_flow_get,
  update_registration_flow,
  set_registration_type,
} from "../../../actions/registrationFlowAction";
import { workflow_get } from "../../../actions/workflowAction";
import { utils_signup } from "../../../utils/utils_signup";
import Spinner from "../../global/spinner";
import { WORKFLOW_VALUE } from "../../../constants/workflow";

class SignupWelcomeBack extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      userAttr: null,
      gettingFlow: null,
    };
  }

  async componentDidMount() {
    if (this.props.loginUser) {
      if (this.props.loginUser.status === "active") {
        return this.props.history.push(PAGES.DASHBOARD);
      }
      if (this.props.loginUser.status === "enrollment") {
        return this.props.history.push(PAGES.ENROLLMENT);
      }
      this.setState({ user: this.props.loginUser });
    }

    const [userAttr, regFlow] = await Promise.all([
      this.props.get_user_attributes_get(),
      this.props.workflow_get(),
    ]);
    this.setState({ userAttr });
    this.prepRegFlow(userAttr, regFlow);
  }

  prepRegFlow(dbAttr, regFlow) {
    this.props.set_registration_type(dbAttr.registration_channel);
    this.findWhereAbandoned(regFlow, dbAttr);
  }

  getRegFlow(onRegFlowSuccess) {
    this.setState({ gettingFlow: true });
    this.props
      .registration_flow_get(true)
      .then((res) => {
        onRegFlowSuccess(res);
        this.setState({ gettingFlow: false });
      })
      .catch(() => this.setState({ gettingFlow: false }));
  }

  findWhereAbandoned(flow, dbAttr) {
    // isolate all exit attributes that are not *
    const exit_attributes = [];
    flow.forEach((c, i) => {
      if (c.exit_attribute && c.exit_attribute.length > 0) {
        c.exit_attribute.forEach((a) => {
          if (a !== "*") {
            const obj = { exit_attribute: a, index: i };
            exit_attributes.push(obj);
          }
        });
      }
    });
    // find all done elements
    const componentsCompleted = exit_attributes.filter((a) => {
      const attObj = a.exit_attribute.split("|");
      const exit_attr = attObj[0];
      return Object.keys(dbAttr).includes(exit_attr);
    });
    // get index of last finished element
    const lastCompletedIndex = componentsCompleted.pop().index;

    if (lastCompletedIndex) {
      // set new flow, all before last done, mark as complete
      const new_flow = flow.map((c, i) => {
        if (i <= lastCompletedIndex) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
        return c;
      });
      utils_signup.goToNextRegistrationStep(
        new_flow,
        new_flow[lastCompletedIndex],
        dbAttr,
        this.props.update_registration_flow,
        this.props.history.push,
        this.props.post_workflow_complete_post,
      );
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  render() {
    return (
      <section className="signup-component abandoned-registration">
        <div className="signup-body-wrapper" style={{ minHeight: "60vh" }}>
          <Spinner error="signup welcome back" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});
export default withRouter(
  connect(mapStateToProps, {
    set_registration_type,
    update_registration_flow,
    registration_flow_get,
    get_user_attributes_get,
    post_workflow_complete_post,
    workflow_get,
  })(SignupWelcomeBack),
);
