import React from "react";
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PhoneNumberComponent from "../core/PhoneNumber/PhoneNumberComponent.tsx";
import { utils } from "../../utils/utils_general";
import { utils_validation } from "../../utils/utils_validation";
import { update_self } from "../../utils/validationrules_api_app";
import {
  UPDATE_SELF_ERROR_RESPONSES,
  UNEXPECTED_ERROR,
  UPDATE_SELF_ERROR_DISPLAY,
  RESPONSE_CODE,
} from "../../constants/errors";
import { EDIT_PERSONAL_MODAL } from "../../constants/editPersonalModal";
import { update_user_put } from "../../actions/userAction";
import { login_get } from "../../actions/loginAction";
import Spinner from "../global/spinner";

class EditEmailModal extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      error: null,
      email: "",
      ph_home: "",
      ph_mobile: "",
      loading: false,
      changed: false,
      start_form: false,
    };
    this.editField = React.createRef();
  }

  componentDidMount() {
    if (this.props.loginUser) {
      const { email, ph_home, ph_mobile } = this.props.loginUser;
      this.setState({
        user: this.props.loginUser,
        email,
        ph_home,
        ph_mobile,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.loginUser && !this.state.user) ||
      this.props.loginUser[this.props.type] !==
        prevProps.loginUser[this.props.type]
    ) {
      const { email, ph_home, ph_mobile } = this.props.loginUser;
      this.setState({
        user: this.props.loginUser,
        email,
        ph_home,
        ph_mobile,
      });
    }
  }

  onLoading(state) {
    this.setState({ loading: state });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.changed) {
      return null;
    }

    let value = this.state[this.props.type];

    if (
      this.props.type === EDIT_PERSONAL_MODAL.HOME_PHONE ||
      this.props.type === EDIT_PERSONAL_MODAL.MOBILE_PHONE
    ) {
      value = value.replace(/-/g, "").replace(/\s/g, "").replace(/[ ,.]/g, "");
    }
    const data = { [this.props.type]: value };
    const errors = utils_validation.validate(update_self, data);
    if (!utils.is_obj_empty(errors)) {
      const R = UPDATE_SELF_ERROR_RESPONSES;
      const D = UPDATE_SELF_ERROR_DISPLAY;
      if (errors.email) {
        if (errors.email === R.EMAIL_INVALID) {
          this.setState({ error: D.EMAIL_INVALID });
        } else if (errors.email === R.EMPTY) {
          this.setState({ error: D.EMAIL_EMPTY });
        }
      } else if (
        (errors.ph_home && errors.ph_home === R.PHONE_INVALID) ||
        (errors.ph_mobile && errors.ph_mobile === R.PHONE_INVALID)
      ) {
        this.setState({ error: D.PHONE_INVALID });
      }
      this.editField.current.focus();
    } else {
      this.onLoading(true);
      this.updateAndRefresh(data);
    }
  }

  updateAndRefresh(data) {
    this.props
      .update_user_put(data, true)
      .then(() => {
        this.props
          .login_get(true)
          .then(() => {
            this.onLoading(false);
            return this.props.onCompleted();
          })
          .catch((error) =>
            this.setState({
              error: `${UNEXPECTED_ERROR}. Please refresh your page.`,
              loading: false,
            }),
          );
      })
      .catch((error) => {
        this.onLoading(false);
        this.editField.current.focus();
        if (
          error.response &&
          error.response.status === RESPONSE_CODE["409_data_conflict"]
        ) {
          return this.setState({
            error: UPDATE_SELF_ERROR_DISPLAY.EMAIL_ALREADY_REGISTERED,
          });
        }
        if (
          error.response &&
          error.response.status === RESPONSE_CODE["405_data_invalid"]
        ) {
          return this.setState({ error: "Data invalid." });
        }

        return this.setState({ error: UNEXPECTED_ERROR });
      });
  }

  handleChange(e) {
    const value = e.target.value.replace(/\(|\)|-| /gi, "") || "";

    this.setState({ [e.target.name]: value });

    if (value === this.state.user[e.target.name]) {
      this.setState({ changed: false });
    } else {
      this.setState({ changed: true });
    }

    if (this.state.error !== null) {
      this.setState({ error: null });
    }
  }

  handleFocus(event) {
    event.target.select();
    if (!this.state.start_form) {
      this.setState({ start_form: true });
    }
  }

  renderLabelText(type) {
    switch (type) {
      case EDIT_PERSONAL_MODAL.EMAIL:
        return "email address";
      case EDIT_PERSONAL_MODAL.HOME_PHONE:
        return "home phone";
      case EDIT_PERSONAL_MODAL.MOBILE_PHONE:
        return "mobile phone";
      default:
        return type;
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner error="loading edit profile" />;
    }
    const { type } = this.props;
    return (
      <>
        <Form noValidate onSubmit={(e) => this.handleSubmit(e)}>
          <Form.Group controlId="ph_mobile">
            <Form.Label>{`${this.props.t("New")} ${this.renderLabelText(type)}`}</Form.Label>
            {type !== EDIT_PERSONAL_MODAL.EMAIL ? (
              <PhoneNumberComponent
                controlRef={this.editField}
                name={type}
                value={this.state[type] || ""}
                onChange={(e) => this.handleChange(e)}
                onFocus={this.handleFocus.bind(this)}
              />
            ) : (
              <Form.Control
                onChange={(e) => this.handleChange(e)}
                name={type}
                type="text"
                value={this.state[type] || ""}
                aria-required="true"
                ref={this.editField}
                onFocus={this.handleFocus.bind(this)}
              />
            )}

            <Form.Text className="text-danger form-error" aria-live="polite">
              {" "}
              {this.props.t(this.state.error) || <br />}{" "}
            </Form.Text>
          </Form.Group>
          <Button
            variant={this.state.changed ? "primary" : "disable"}
            type="submit"
            block
          >
            {this.props.t("Confirm")}
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { update_user_put, login_get })(
  withTranslation()(EditEmailModal),
);
