import { Dispatch } from "redux";
import { DnaKitOrderData } from "../../services/types/DnaKit";
import { DispatchableAction } from "../../services/types/IStore";
import { OrderDnaKitInterface } from "../../services/types/validations/OrderDnaKit";
import types, { DnaKitAction } from "./Types";

const doDispatch =
  (action: DnaKitAction): DispatchableAction =>
  (dispatch: Dispatch) =>
    dispatch(action);

export const doSetDnaKitOrderPayload = (
  data: OrderDnaKitInterface,
): DispatchableAction =>
  doDispatch({
    type: types.SET_DNA_KIT_ORDER_PAYLOAD,
    data,
  });

export const doSetDnaKitOrderRequesting = (): DispatchableAction =>
  doDispatch({ type: types.SET_DNA_KIT_ORDER_REQUESTING });

export const doSetDnaKitCreatedOrder = (
  data: DnaKitOrderData | undefined,
): DispatchableAction =>
  doDispatch({
    type: types.SET_DNA_KIT_CREATED_ORDER,
    data,
  });

export const doSetDnaKitOrderErrors = (data: Error[]): DispatchableAction =>
  doDispatch({
    type: types.SET_DNA_KIT_ORDER_ERRORS,
    data,
  });
