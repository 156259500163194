import React from "react";
import { connect } from "react-redux";
import Button, { ButtonType } from "../core/Button/Button";

export type WorkflowNextButtonProps = {
  spinner?: boolean;
  inline?: boolean;
} & ButtonType;

function WorkflowNextButton({
  spinner,
  inline,
  disabled,
  onClick,
  innerValue,
  className,
  children,
  variant,
  size = "lg",
  block = true,
  type,
}: WorkflowNextButtonProps) {
  const isDisabled = spinner || disabled;

  return (
    <Button
      onClick={onClick}
      innerValue={innerValue}
      children={children}
      variant={isDisabled && variant !== "link" ? "" : variant}
      size={size}
      className={className}
      block={!inline && block}
      type={type}
      disabled={isDisabled}
    />
  );
}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps)(WorkflowNextButton);
