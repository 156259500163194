import ConfirmEmail from "../confirm-email/confirm_email";
import { withRouter, useHistory } from "react-router-dom";
import { login_get } from "../../actions/loginAction";
import { useDispatch } from "react-redux";
import PAGES from "../../constants/pages";

const ConfirmEmailWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const wrapperComplete = async () => {
    const login_get_action = login_get();
    await dispatch(login_get_action);
    history.push(PAGES.DASHBOARD);
  };

  return (
    <>
      <ConfirmEmail completedForm={wrapperComplete} />
    </>
  );
};

export default withRouter(ConfirmEmailWrapper);
