import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PAGES from "../../constants/pages";
import { FEATURE } from "../../constants/features";
import IconGenerator from "../icons/icon_generator";
import SuggestedSurveys from "../dashboard/suggested_surveys";
import AncestryMessage from "../global/ancestry_message";
import { utils } from "../../utils/utils_general";
import { get_survey_list_get } from "../../actions/surveyAction";
import { withTranslation } from "react-i18next";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

class SurveyComplete extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      survey: null,
      iframe: null,
      done: false,
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.survey &&
      !utils.is_obj_empty(this.props.location.state.survey)
    ) {
      this.setState({ survey: this.props.location.state.survey });
      this.props.get_survey_list_get(true);
      setTimeout(() => {
        this.setState({ done: true });
      }, 1000);
    } else {
      this.props.history.push(PAGES.SURVEYS);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !utils.check_objects_identical(
        this.props.location.state.survey,
        prevProps.location.state.survey,
      )
    ) {
      this.setState({ survey: this.props.location.state.survey, done: true });
    }
  }

  renderAncestryMessage() {
    return (
      <div className="section-component">
        <AncestryMessage />
      </div>
    );
  }

  renderSuggestedSurveys(survey) {
    if (this.state.done) {
      return (
        <SuggestedSurveys type="card" excludedSurveyId={survey.survey_id} />
      );
    }
    return null;
  }

  render() {
    const { survey } = this.state;

    if (!survey) {
      return null;
    }

    return (
      <section className="survey-complete container">
        <div className="row">
          <aside className="col-12 col-xl-2 left-section" />
          <div className="col-12 col-xl-8 center-section">
            <div className="mobile-only">
              <div className="survey-page-icon-wrapper">
                <IconGenerator iconKey={survey.limesurvey_id} />
              </div>
            </div>

            <h3 className="h1 mb-3">{this.props.t("Thank you!")}</h3>
            <div className="survey-complete-content">
              <div className="section-component">
                <HtmlContentComponent
                  tag={"div"}
                  markup={this.props.t("Survey_complete_description", {
                    survey_title: survey.title,
                  })}
                />
              </div>

              {this.props.featureFlag &&
              this.props.featureFlag[FEATURE.ANCESTRY]
                ? this.renderAncestryMessage()
                : null}
            </div>

            {this.renderSuggestedSurveys(survey)}
          </div>
          <aside className="col-12 col-xl-2 right-section">
            <div className="survey-page-icon-wrapper">
              <IconGenerator iconKey={survey.limesurvey_id} />
            </div>
          </aside>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, { get_survey_list_get })(
    withTranslation()(SurveyComplete),
  ),
);
