import React from "react";
import { CONSENT_COMPONENT } from "../../constants/consentComponents";
import AncestrySubcompCollapsibleSection from "./ancestry_components/ancestry_subcomp_collapsible_section";
import ConsentSubcompLink from "../consent/consent_components/consent_subcomp_link";
import ConsentSubcompText from "../consent/consent_components/consent_subcomp_text";
import ConsentSubcompVideo from "../consent/consent_components/consent_subcomp_video";

export const AncestrySubcompRenderer = (props) => {
  switch (props.component.type) {
    case CONSENT_COMPONENT.TEXT:
      return <ConsentSubcompText text={props.component} />;
    case CONSENT_COMPONENT.COLLAPSIBLE_SECTION:
      return (
        <AncestrySubcompCollapsibleSection
          subcomponentKey={props.subcomponentKey}
          collapsibleSection={props.component}
        />
      );
    case CONSENT_COMPONENT.LINK:
      return (
        <ConsentSubcompLink
          subcomponentKey={props.subcomponentKey}
          link={props.component}
        />
      );
    case CONSENT_COMPONENT.VIDEO:
      return <ConsentSubcompVideo video={props.component} />;
    default:
      return null;
  }
};
export default AncestrySubcompRenderer;
