import React from "react";
import { withTranslation } from "react-i18next";
import { LOCAL_STORAGE } from "../../../constants/localStorage";
import { utils } from "../../../utils/utils_general";
import ConsentSubcompRenderer from "../../consent/consent_subcomp_renderer";
import WorkflowNextButton from "../../workflow/WorkflowNextButton";

class ConsentCompEsignConfirm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  onSubmit() {
    this.setState({ loading: true });
    this.props.onComponentComplete({ index: this.props.index });
  }

  renderSignatureImage(signatureImg) {
    if (!signatureImg) {
      return (
        <p className="text-danger text-center">
          {this.props.t("Your e-signature is missing, please go back.")}
        </p>
      );
    }

    return (
      <div className="sig-container-confirm">
        <img src={signatureImg} alt={this.props.t("Your signature preview.")} />{" "}
      </div>
    );
  }

  render() {
    const { component, t, onCancel, signature: propsSignature } = this.props;

    const signature =
      propsSignature || utils.get_local_storage(LOCAL_STORAGE.SIG);
    const signatureImg = signature?.sig || null;
    const enabled = signatureImg && !this.state.loading;

    return (
      <div className="consent-component consent-component-esign-confirm">
        {/* <h2 className="h5 mb-3">{component.title}</h2> */}
        <div className="consent-subcomponents mb-4">
          {component.body.map((c, key) => (
            <ConsentSubcompRenderer key={key} type={c.type} component={c} />
          ))}
        </div>

        <div className="unauth-body-footer">
          <div>{this.renderSignatureImage(signatureImg)}</div>
          <div className="text-center">
            <WorkflowNextButton
              disabled={!enabled}
              size={"sm"}
              inline
              className="pl-3 pr-3"
              type="submit"
              onClick={() => this.onSubmit()}
            >
              {t("I confirm my consent and understanding")}
            </WorkflowNextButton>
          </div>
          <div className="text-center">
            <WorkflowNextButton
              variant="link"
              size={"sm"}
              inline
              className="p-0 mt-2"
              type="submit"
              onClick={() => onCancel()}
            >
              {t("Cancel")}
            </WorkflowNextButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConsentCompEsignConfirm);
