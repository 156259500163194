import { MAX_CONSENT_BANNER_COUNT } from "../constants/recoveryBanner";

export const utils_consent_banner = {
  get_next_banner: (previous_banner) => {
    if (!previous_banner) {
      return Math.random() < 0.5 ? "hero" : "heart";
    }
    return previous_banner === "hero" ? "heart" : "hero";
  },
  is_max_reached: (banner_count) => {
    return banner_count >= MAX_CONSENT_BANNER_COUNT;
  },
};
