import { Case, When } from "../../../core/PredicateRouter";
import {
  InnerWorkflow,
  InnerWorkflowStepProps,
  WorkflowStepProps,
} from "../../../../services/types/WorkflowTypes";
import InnerWorkflowComponent from "../../InnerWorkflow/InnerWorkflowComponent";
import OrderDnaKitCheck from "./OrderDnaKitCheck";
import OrderDnaKitConfirm from "./OrderDnaKitConfirm";
import OrderDnaKitForm from "./OrderDnaKitForm";
import OrderDnaKitReceipt from "./OrderDnaKitReceipt";
import DnaKitOnsite from "./DnaKitOnsite";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get_dna_kit_onsite } from "../../../../actions/dnaKitOnsiteAction";

const OrderDnaKitInnerWorkflow: InnerWorkflow = [
  { step: "OrderDnaKitCheck", excludeFromProgress: true },
  { step: "OrderDnaKitForm" },
  { step: "OrderDnaKitConfirm" },
  { step: "OrderDnaKitReceipt", excludeFromProgress: true },
];

function OrderDnaKitStep({ onComponentComplete }: WorkflowStepProps) {
  const dispatch = useDispatch<any>();
  const kitCode = useSelector((state: any) => state.kitCode);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getKitCode = async () => {
      await dispatch(get_dna_kit_onsite());
      setLoaded(true);
    };

    getKitCode();
  }, [dispatch]);

  if (!loaded) {
    return null;
  }

  const renderWorkflow = () => (
    <InnerWorkflowComponent
      innerWorkflow={OrderDnaKitInnerWorkflow}
      onInnerWorkflowComplete={() => onComponentComplete()}
      elementSelector={(stepProps: InnerWorkflowStepProps) => {
        const { step: currentStep } = stepProps.step;
        return (
          <Case>
            <When
              predicate={currentStep === "OrderDnaKitCheck"}
              component={OrderDnaKitCheck}
              props={{ ...stepProps, onComponentComplete }}
            />
            <When
              predicate={currentStep === "OrderDnaKitForm"}
              component={OrderDnaKitForm}
              props={stepProps}
            />
            <When
              predicate={currentStep === "OrderDnaKitConfirm"}
              component={OrderDnaKitConfirm}
              props={stepProps}
            />
            <When
              predicate={currentStep === "OrderDnaKitReceipt"}
              component={OrderDnaKitReceipt}
              props={stepProps}
            />
          </Case>
        );
      }}
    />
  );

  const renderDnaKitOnsite = () => (
    <DnaKitOnsite
      kitCode={kitCode}
      onStepComplete={() => onComponentComplete()}
    />
  );

  return kitCode ? renderDnaKitOnsite() : renderWorkflow();
}

export default OrderDnaKitStep;
