import { useTranslation } from "react-i18next";
import hero from "../../../assets/images/ancestry_hero.svg";
import PAGES from "../../../constants/pages";

const AncestryLandingPageHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="page-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="text-center">
              <p className="side-heading">{t("Ancestry_Header_Subtitle")}</p>
              <h2>{t("Ancestry_Header_Title")}</h2>
              <p>{t("Ancestry_Header_Description")}</p>
            </div>
            <div className="landing-hero-links text-center pt-4">
              <a href={PAGES.SIGNUP} className="landing-hero-button">
                {t("Ancestry_Header_Button")}
              </a>
            </div>
          </div>
          <div className="col-lg-5 text-md-center">
            <img src={hero} alt="" className="ml-lg-5 img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AncestryLandingPageHeader;
