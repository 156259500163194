import React from "react";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";
import VideoPlayer from "./videoPlayer";

export class TextMediaWithNext extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      vidComplete: [],
      allVidsWatched: false,
    };
  }

  onVidComplete(index) {
    const { vidComplete } = this.state;
    if (!vidComplete.includes(index)) {
      vidComplete.push(index);
      this.setState({ vidComplete });

      const vidsWithReqSec = this.props.body.filter(
        (c) => c.videoRequiredWatchSeconds,
      );
      if (vidComplete.length === vidsWithReqSec.length) {
        this.setState({ allVidsWatched: true });
      }
    }
  }

  renderTitle(title) {
    return <h2 className="h3">{title}</h2>;
  }

  renderImage(url, alt) {
    return (
      <div className="mt-2 mb-2">
        <img src={url} style={{ width: "100%" }} alt={alt || ""} />
      </div>
    );
  }

  renderVideo(url, requiredSeconds, index) {
    return (
      <div className="mt-2 mb-2">
        <VideoPlayer
          onVidComplete={(index) => this.onVidComplete(index)}
          videoIndex={index}
          videoUrl={url}
          reqWatchSec={requiredSeconds ? Number(requiredSeconds) : null}
        />
      </div>
    );
  }

  renderText(text) {
    return <HtmlContentComponent markup={text} />;
  }

  render() {
    const { t, body } = this.props;
    if (!body) {
      return null;
    }

    const vidsWithReqSec = this.props.body.filter(
      (c) => c.videoRequiredWatchSeconds,
    );
    const buttonVariant =
      this.state.allVidsWatched || vidsWithReqSec.length < 1
        ? "primary"
        : "disable";

    if (!t) {
      return (
        <>
          <div className="text-with-next mb-5">
            {body.map((c, i) => (
              <div key={i} className="text-with-next-component mb-3">
                {c.title ? this.renderTitle(c.title) : null}
                {c.imgUrl ? this.renderImage(c.imgUrl, c.imgAlt) : null}
                {c.videoUrl
                  ? this.renderVideo(c.videoUrl, c.videoRequiredWatchSeconds, i)
                  : null}
                {c.text ? this.renderText(c.text) : null}
              </div>
            ))}
          </div>

          <div className="next-text-media" style={{ marginBottom: "19px" }}>
            <Button
              variant={buttonVariant}
              onClick={() => this.props.onNext()}
              block
            >
              Continue
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="text-with-next mb-5">
          {body.map((c, i) => (
            <div key={i} className="text-with-next-component mb-3">
              {c.title ? this.renderTitle(t(c.title)) : null}
              {c.imgUrl ? this.renderImage(c.imgUrl, t(c.imgAlt)) : null}
              {c.videoUrl
                ? this.renderVideo(c.videoUrl, c.videoRequiredWatchSeconds, i)
                : null}
              {c.text ? this.renderText(t(c.text)) : null}
            </div>
          ))}
        </div>

        <div className="next-text-media" style={{ marginBottom: "19px" }}>
          <Button
            variant={buttonVariant}
            onClick={() => this.props.onNext()}
            block
          >
            {t("Continue")}
          </Button>
        </div>
      </>
    );
  }
}

export default withTranslation("fields")(TextMediaWithNext);
