import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { utils_api } from "../utils/utils_api.js";
import { api_dna_kit_onsite } from "../api/api_dna_kit_onsite.js";

export function get_dna_kit_onsite(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "get_dna_kit_onsite",
      );
    }

    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.DNA_KIT_ONSITE,
        data: response?.data?.data ?? null,
      });
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    return api_dna_kit_onsite.get_dna_kit_onsite(success, fail);
  };
}
