import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable camelcase */
import {
  post_workflow_complete_post,
  get_user_attributes_get,
} from "../../../actions/userAction";
import { login_get } from "../../../actions/loginAction";
import { update_registration_flow } from "../../../actions/registrationFlowAction";
import { utils_signup } from "../../../utils/utils_signup";
import { utils_registration_flow } from "../../../utils/utils_registration_flow";
/* eslint-enable camelcase */

import SignupProgress from "../../signup/signup_progress";
import CategorizationValidation from "../../global/categorization_validation";
import { WORKFLOW, WORKFLOW_VALUE } from "../../../constants/workflow";
import PAGES from "../../../constants/pages";
import { useCurrentUser } from "../../../services/UserHooks";
import { clearReloadState } from "./service_regflow";

const checkProgress = (registrationFlow, userAttribute) => {
  let progressChanged = false;

  const regFlowWithProgress = registrationFlow.map((step) => {
    if (step.component !== WORKFLOW.CATEGORIZATION || step.value) {
      return step;
    }

    const { exit_attribute: exitAttribute } = step;

    if (exitAttribute.length === 1 && exitAttribute[0] === "*") {
      return step;
    }

    const value =
      utils_registration_flow.checkAttrCompleted(exitAttribute, userAttribute)
        .length === 0
        ? WORKFLOW_VALUE.COMPLETE
        : null;

    if (value !== step.value) {
      progressChanged = true;
    }

    return {
      ...step,
      value,
    };
  });

  return [progressChanged, regFlowWithProgress];
};

function SignupCategorizationValidation({
  registrationFlow,
  history,
  get_user_attributes_get: getUserAttributesGet,
  update_registration_flow: updateRegistrationFlow,
  post_workflow_complete_post: postWorkflowCompletePost,
  userAttribute,
}) {
  const [errors, setErrors] = useState(null);
  const [currentStep, setCurrentStep] = useState();
  const currentUser = useCurrentUser();

  /**
   * @returns {void}
   */
  const currentStepCheck = () => {
    const stepToExecute = registrationFlow.find(
      ({ component, value }) =>
        component === WORKFLOW.CATEGORIZATION && value == null,
    );

    if (stepToExecute === undefined) {
      const lastCompleted = registrationFlow.findLast(
        ({ component, value }) =>
          component === WORKFLOW.CATEGORIZATION && value != null,
      );
      utils_signup.goToNextRegistrationStep(
        registrationFlow,
        lastCompleted,
        userAttribute,
        updateRegistrationFlow,
        history.push,
        postWorkflowCompletePost,
      );
      return;
    }

    const attrErrors = utils_registration_flow.checkAttrCompleted(
      stepToExecute.entry_attribute,
      userAttribute,
    );

    // entry attribute not met
    if (attrErrors.length > 0) {
      const redirectInfo = utils_registration_flow.redirectToAttribute(
        attrErrors,
        registrationFlow,
      );
      utils_signup.goToRegistrationStep(
        redirectInfo.flow,
        redirectInfo.step,
        userAttribute,
        updateRegistrationFlow,
        history.push,
        postWorkflowCompletePost,
      );
      return;
    }

    setCurrentStep(stepToExecute);
  };

  // Ensure userAttribute state prop is set.
  useEffect(() => {
    if (!currentUser) {
      history.push(PAGES.SIGNUP);
      return;
    }
    if (!userAttribute && currentUser) {
      getUserAttributesGet().catch((error) => setErrors(error));
    }
  }, [userAttribute, currentUser]);

  // Ensure workflow data is up-to-date.
  useEffect(() => {
    if (userAttribute && registrationFlow) {
      const [shouldUpdateFlow, regFlowWithProgress] = checkProgress(
        registrationFlow,
        userAttribute,
      );
      if (shouldUpdateFlow) {
        updateRegistrationFlow(regFlowWithProgress);
      }
    }
  }, [registrationFlow, userAttribute]);

  // Make sure to display the correct step.
  useEffect(() => {
    if (registrationFlow) {
      currentStepCheck();
    }
  }, [registrationFlow, currentStep]);

  const handleFormComplete = async () => {
    try {
      clearReloadState();
      await getUserAttributesGet();
      // Explicitly mark step as complete
      updateRegistrationFlow(
        registrationFlow.map((step) =>
          step.id === currentStep.id
            ? { ...step, value: WORKFLOW_VALUE.COMPLETE }
            : step,
        ),
      );
    } catch (error) {
      setErrors(error);
    }
  };

  if (!currentStep) {
    return null;
  }

  return (
    <section className="signup-component signup-categorization">
      <SignupProgress currentStep={currentStep} regFlow={registrationFlow} />
      <div className="signup-body-wrapper">
        <CategorizationValidation
          qn={currentStep.qn}
          onComplete={handleFormComplete}
          errors={errors}
        />
      </div>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  workflowIsLoading: state.workflow.workflowIsLoading,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, {
    login_get,
    update_registration_flow,
    post_workflow_complete_post,
    get_user_attributes_get,
  })(SignupCategorizationValidation),
);
