import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PAGES from "../../constants/pages";
import { post_user_action_post } from "../../actions/userAction";
import { USER_ACTION } from "../../constants/userAction";
import { FEATURE } from "../../constants/features";
import { DEEPLINK_ID, DEEPLINKS } from "../../constants/deeplinks";
import { utils } from "../../utils/utils_general";
import { URL_ROOT_API } from "../../constants/api";
import { ReactComponent as NewWindowIcon } from "../../assets/icons/new-window.svg";
import {
  config_privacy_get,
  config_terms_get,
} from "../../actions/configAction";
import { ContextProvider } from "../../services/ServiceProvider";
import { doSetConsentLoading } from "../../store/Consent/ConsentActions";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

class Legal extends React.Component {
  static contextType = ContextProvider;

  constructor() {
    super();
    this.state = {
      expandConsent: false,
    };
  }

  componentDidMount() {
    const { container } = this.context;
    this.checkDeepLink();
    this.consentService = container.get("consentService");
  }

  checkDeepLink() {
    const params = utils.get_url_params();
    if (params[DEEPLINKS.CONSENT]) {
      this.setState({ expandConsent: true });
      const el = document.getElementById(DEEPLINK_ID.CONSENT);
      utils.scroll_to_deeplink_el(el);
    }
  }

  async onDownloadConsent() {
    const zipDownloadToken = await this.consentService.getAllFilesAsZip({
      doSetConsentLoading: this.props.doSetConsentLoading,
    });
    this.updateUserAction().then(() => {
      window.location.assign(
        `${URL_ROOT_API}/download?token=${zipDownloadToken}`,
      );
    });
  }

  updateUserAction() {
    return this.props
      .post_user_action_post(USER_ACTION.CONSENT_DOWNLOAD, true)
      .then((response) => {})
      .catch((error) => this.setState({ error: { system: error } }));
  }

  renderConsentExpanded() {
    const { t } = this.props;
    return (
      <div className="pt-3 pb-3 pl-lg-3 pr-lg-3">
        <div className="mb-3">
          <HtmlContentComponent
            markup={t(
              "We recommend you download your consent for participating in this research program for your records.",
            )}
          />
        </div>
        <div className="text-right">
          <Button
            variant="primary"
            className="profile-cta"
            onClick={() => this.onDownloadConsent()}
          >
            {t("Download your consents")}
          </Button>
        </div>
      </div>
    );
  }

  prefetchPrivacyPolicy() {
    if (!this.props.privacyContent) {
      this.props.config_privacy_get(true);
    }
  }

  prefetchTerms() {
    if (!this.props.termsContent) {
      this.props.config_terms_get(true);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="legal-profile section-component">
        <h2 className="mb-3 h3">{t("Legal")}</h2>
        <div className="legal-wrapper">
          <div className="legal-accordian-wrapper">
            <a
              href={PAGES.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => this.prefetchPrivacyPolicy()}
            >
              <div className="legal-accordian legal-accordian-link">
                {t("Privacy Policy")}
                <div className="icon-wrapper">
                  <NewWindowIcon />
                </div>
              </div>
            </a>
          </div>
          <div className="legal-accordian-wrapper">
            <a
              href={PAGES.TERMS_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => this.prefetchTerms()}
            >
              <div className="legal-accordian legal-accordian-link">
                {t("Terms & Conditions")}
                <div className="icon-wrapper">
                  <NewWindowIcon />
                </div>
              </div>
            </a>
          </div>
          {this.props.featureFlag && this.props.featureFlag[FEATURE.CONSENT] ? (
            <div
              id={DEEPLINK_ID.CONSENT}
              className={`legal-accordian-wrapper${this.state.expandConsent ? " expanded" : ""}`}
            >
              <Button
                variant="primary-invert"
                className="legal-accordian"
                block
                onClick={() =>
                  this.setState({ expandConsent: !this.state.expandConsent })
                }
              >
                {t("Confirmed Consent")}
              </Button>
              {this.state.expandConsent ? this.renderConsentExpanded() : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  // location: ownProps.location
});

const mapDispatchProps = {
  post_user_action_post,
  config_privacy_get,
  config_terms_get,
  doSetConsentLoading,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(withTranslation()(Legal)),
);
