import React, { useEffect, useRef, useState } from "react";
import {
  InnerWorkflow,
  InnerWorkflowStepProps,
} from "../../../services/types/WorkflowTypes";
import InnerWorkflowProgress from "./InnerWorkflowProgress";

// These are the props directly used when returning the component: <InnerWorkflowComponent prop1={} prop2={} />
type InnerWorkflowComponentComponentProps = {
  innerWorkflow?: InnerWorkflow;
  onInnerWorkflowComplete?: () => void;
  elementSelector?: (props: InnerWorkflowStepProps) => React.ReactElement;
};

export type InnerWorkflowComponentProps = InnerWorkflowComponentComponentProps;

function InnerWorkflowComponent({
  innerWorkflow = [],
  onInnerWorkflowComplete = () => {},
  elementSelector = () => <>Unknown Inner Workflow Element</>,
}: InnerWorkflowComponentProps) {
  const ElementSelector = elementSelector;
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topRef.current?.parentElement?.scrollIntoView(true);
  }, [currentStepIndex]);

  const handleStepComplete = () => {
    const nextStep = currentStepIndex + 1;

    if (nextStep >= innerWorkflow.length) {
      return onInnerWorkflowComplete();
    }

    setCurrentStepIndex(nextStep);
  };

  const handleStepBack = () => {
    const previousStep = currentStepIndex - 1;

    if (previousStep >= 0) {
      setCurrentStepIndex(previousStep);
    }
  };

  const currentStep = innerWorkflow[currentStepIndex];

  return (
    <div className="p-4 bg-secondary rounded" ref={topRef}>
      <InnerWorkflowProgress
        currentStep={currentStep}
        innerWorkflow={innerWorkflow}
      />

      <ElementSelector
        step={currentStep}
        onStepComplete={handleStepComplete}
        onStepBack={handleStepBack}
      />
    </div>
  );
}

export default InnerWorkflowComponent;
