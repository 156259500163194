import { useTranslation } from "react-i18next";
import styles from "./referral-landing-page-b-content.module.scss";
import classNames from "classnames";
import PAGES from "../../../constants/pages";
import { ReactComponent as IconHome } from "../../../assets/icons/History.svg";
import { ReactComponent as IconProfile } from "../../../assets/icons/Test.svg";
import { ReactComponent as IconSurveys } from "../../../assets/icons/Saliva.svg";
import { ReactComponent as IconEmail } from "../../../assets/icons/Ancestry.svg";
import { ReactComponent as IconClappingHand } from "../../../assets/icons/icon_high_five.svg";
import globeSmall from "../../../assets/images/ancestry-globe-small-white-bg.gif";
import globeMedium from "../../../assets/images/ancestry-globe-medium-white-bg.gif";
import globeLarge from "../../../assets/images/ancestry-globe-large-white-bg.gif";

export default function ReferralLandingPageBDesktopContent(props) {
  const { t } = useTranslation();
  const { loginUser } = props;

  function LandingCards() {
    return (
      <>
        <div className={classNames(styles.landingCardsContent, "row")}>
          <div
            className={classNames(
              styles.cardBorder,
              "col-sm-6 col-md-6 col-xl-3",
            )}
          >
            <div className={classNames(styles.landingCard)}>
              <IconProfile />
              <div className={classNames(styles.cardDescription)}>
                <h4>{t("Referral_B_Card_Step_1")} </h4>
                <h3>{t("Referral_B_Card_Title_1")}</h3>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.cardBorder,
              "col-sm-6 col-md-6 col-xl-3",
            )}
          >
            <div className={classNames(styles.landingCard)}>
              <div className="svg-icon">
                <IconHome />
              </div>
              <div className={classNames(styles.cardDescription)}>
                <h4>{t("Referral_B_Card_Step_2")} </h4>
                <h3>{t("Referral_B_Card_Title_2")}</h3>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.cardBorder,
              "col-sm-6 col-md-6 col-xl-3",
            )}
          >
            <div className={classNames(styles.landingCard)}>
              <div className="svg-icon">
                <IconSurveys />
              </div>
              <div className={classNames(styles.cardDescription)}>
                <h4>{t("Referral_B_Card_Step_3")} </h4>
                <h3>{t("Referral_B_Card_Title_3")}</h3>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.cardBorder,
              "col-sm-6 col-md-6 col-xl-3",
            )}
          >
            <div className={classNames(styles.landingCard)}>
              <div className="svg-icon">
                <IconEmail />
              </div>
              <div className={classNames(styles.cardDescription)}>
                <h4>{t("Referral_B_Card_Step_4")} </h4>
                <h3>{t("Referral_B_Card_Title_4")}</h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classNames(styles.referralContent)}>
        <div className={classNames(styles.referralContentWrapper)}>
          <div className={classNames(styles.referralTitle)}>
            <h2>{t("Referral_B_Title")}</h2>
          </div>
          <div className={classNames(styles.referralDescription)}>
            <p>
              {t("Referral_B_SubTitle", {
                first_name: loginUser?.first_name || "",
                last_name: loginUser?.last_name || "",
              })}
            </p>
            <p>{t("Referral_B_Description")}</p>
          </div>
          <LandingCards />
          <a
            href={PAGES.SIGNUP}
            className={classNames(styles.landingCardsBanner)}
          >
            <div className={classNames(styles.cardBannerLeftText)}>
              {t("Referral_B_Hands_Left")}
            </div>
            <IconClappingHand />
            <div className={classNames(styles.cardBannerRightText)}>
              <div className={classNames(styles.cardLink)}>
                {t("Referral_B_Hands_Right")}
                <i className={classNames(styles.arrowRight)} />
              </div>
            </div>
          </a>
          <div className={classNames(styles.referralAncestryTitle)}>
            {t("Referral_B_Ancestry_Title")}
          </div>
          <p className={classNames(styles.referralAncestryParagraph)}>
            {t("Referral_B_Ancestry_Subtitle")}
          </p>
          <div className="col-12">
            <div className="d-block d-md-none">
              <img
                src={globeSmall}
                alt="ancestry-globe-small"
                className="w-100 h-100"
              />
            </div>
            <div className="d-none d-md-block d-lg-none">
              <img src={globeMedium} alt="ancestry-globe-medium" />
            </div>
            <div className="d-none d-lg-block">
              <img src={globeLarge} alt="ancestry-globe-large" />
            </div>
          </div>
          <div className="landing-hero-links text-center pt-3">
            <a href={PAGES.ANCESTRY_CONTENT} className="landing-hero-button">
              {t("Referral_B_Ancestry_Button")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
