import classNames from "classnames";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PAGES from "../../constants/pages";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";
import { ReferralLandingPageFooter } from "./referral-landing-page-footer";
import { ReferralLandingPageHeader } from "./referral-landing-page-header";

import styles from "./referral-landing-page.module.scss";

export function ReferralLandingPage() {
  const { t } = useTranslation();
  return (
    <div className={classNames("unauth")}>
      <ReferralLandingPageHeader />
      <div className={classNames(styles.upperSection, "px-3")}>
        <h1 className={classNames(styles.title, "text-center")}>
          {t("Referral_Landing_title")}
        </h1>

        <section
          id="section-1"
          className={classNames(styles.section1, "bg-white text-body")}
        >
          <h2 className="text-center">
            {t("Referral_Landing_section1_title")}
          </h2>
          <HtmlContentComponent
            className="text-center px-2"
            markup={t("Referral_Landing_section1_description")}
          />
          <div
            className={classNames(
              styles.section1Buttons,
              "d-flex flex-column align-items-center",
            )}
          >
            <a href="/" className={styles.section1Link}>
              {t("Referral_Landing_section1_link_text")}
            </a>
            <Button href={PAGES.SIGNUP} variant="primary">
              {t("Referral_Landing_signup_button_text")}
            </Button>
          </div>
        </section>
      </div>

      <div className={classNames(styles.bottomSection, "text-body")}>
        <section
          id="section-2"
          className={classNames(styles.section2, "p-3 text-center")}
        >
          <h2>{t("Referral_Landing_section2_title")}</h2>
          <div className={styles.cards}>
            <div>
              <h3>{t("Referral_Landing_section2_card1_title")}</h3>
              <HtmlContentComponent
                markup={t("Referral_Landing_section2_card1_description")}
              />
            </div>
            <div>
              <h3>{t("Referral_Landing_section2_card2_title")}</h3>
              <HtmlContentComponent
                markup={t("Referral_Landing_section2_card2_description")}
              />
            </div>
            <div>
              <h3>{t("Referral_Landing_section2_card3_title")}</h3>
              <HtmlContentComponent
                markup={t("Referral_Landing_section2_card3_description")}
              />
            </div>
            <div>
              <h3>{t("Referral_Landing_section2_card4_title")}</h3>
              <HtmlContentComponent
                markup={t("Referral_Landing_section2_card4_description")}
              />
            </div>
          </div>
        </section>

        <section
          id="section-3"
          className={classNames(styles.section3, "p-3 text-center")}
        >
          <h2>{t("Referral_Landing_section3_title")}</h2>
          <HtmlContentComponent
            className="text-center mb-3"
            markup={t("Referral_Landing_section3_description")}
          />
          <div className={styles.section3Content}>
            <div>
              <h3>{t("Referral_Landing_section3_card1_title")}</h3>
              <p>{t("Referral_Landing_section3_card1_description")}</p>
              <HtmlContentComponent
                markup={t("Referral_Landing_section3_card1_description")}
              />
            </div>
            <div>
              <h3>{t("Referral_Landing_section3_card2_title")}</h3>
              <HtmlContentComponent
                markup={t("Referral_Landing_section3_card2_description")}
              />
            </div>
          </div>
        </section>

        <div className={classNames(styles.bottomSectionButton, "text-center")}>
          <Button href={PAGES.SIGNUP} variant="primary">
            {t("Referral_Landing_signup_button_text")}
          </Button>
        </div>

        <ReferralLandingPageFooter />
      </div>
    </div>
  );
}
