import React from "react";
import { Button } from "react-bootstrap";
import { ENV_STRING } from "../../../constants/api";
import { utils } from "../../../utils/utils_general";
import { utils_workflow } from "../../../utils/utils_workflow";

const isDevEnvironment =
  ENV_STRING === "DEV" ||
  ENV_STRING === "DEV2" ||
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("local");

const isMobileSurvey =
  utils_workflow.isCurrentStepOfType("survey") && utils.is_mobile();

// These are the props directly used when returning the component: <DevSkipSurveyButton prop1={} prop2={} />
export type DevSkipSurveyButtonComponentProps = {
  onSkipSurvey: () => void;
};

export type DevSkipSurveyButtonProps = DevSkipSurveyButtonComponentProps;

function DevSkipSurveyButton({
  onSkipSurvey,
}: DevSkipSurveyButtonProps): React.ReactElement | null {
  if (!isDevEnvironment || isMobileSurvey) {
    return null;
  }

  return (
    <Button size="sm" variant="light" onClick={() => onSkipSurvey()}>
      Dev env only, get on with your life
    </Button>
  );
}

export default DevSkipSurveyButton;
