import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import PAGES from "../../constants/pages";
import { get_user_attributes_get } from "../../actions/userAction";

class WorkflowPause extends React.Component {
  async removeStartAttrAndRedirect() {
    const { history, onWorkflowExit, get_user_attributes_get } = this.props;

    if (onWorkflowExit) {
      await onWorkflowExit();
    }

    await get_user_attributes_get();
    return history.push(PAGES.DASHBOARD);
  }

  render() {
    switch (this.props.type) {
      case "enrollment":
        return (
          <Link className="btn btn-primary block" to={PAGES.ENROLLMENT}>
            {this.props.t("Return to Overview")}
          </Link>
        );
      default:
        return (
          <Button
            className="btn btn-primary block"
            onClick={() => this.removeStartAttrAndRedirect(this.props.step)}
          >
            {this.props.t("Return to Dashboard")}
          </Button>
        );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  subworkflows: state.workflow.subworkflows,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});
export default withRouter(
  connect(mapStateToProps, { get_user_attributes_get })(
    withTranslation()(WorkflowPause),
  ),
);
