import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = null;
function utmParamsReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.SET_UTM_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default utmParamsReducer;
