/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PAGES from "../../constants/pages";
import {
  actioncards_get,
  actioncards_delete,
} from "../../actions/actioncardsAction";
import IconGenerator from "../icons/icon_generator";
import { utils } from "../../utils/utils_general";
import { utils_user } from "../../utils/utils_user";
import ActionCard from "../global/action_card";
import Spinner from "../global/spinner";
import SurveyConsentModal from "../survey/survey_consent_modal";

function CardsPlaceHolder() {
  return (
    <>
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="action-card-wrapper col-12 col-md-3 mb-2">
          <div className="action-card bg-secondary p-3 rounded clearfix">
            <div className="action-card-img">
              <div className="action-card-img-wrapper" />
            </div>
            <div className="action-card-description pb-0 pb-md-3">
              <p className="color-brand">
                <br />
              </p>
              <p className="h4 color-dark">
                <br />
              </p>
            </div>

            <div className="action-card-mobile-chevron">
              <div className="symbol-chevron" />
            </div>
          </div>
        </div>
      ))}
      <Spinner error="action card placeholders" />
    </>
  );
}

class ActionCards extends React.Component {
  isComponentMounted = false;

  constructor() {
    super();
    this.state = {
      cards: null,
      errors: {},
      showDetails: false,
      cacheIsDeleted: false,
      cardsLoaded: false,
    };
    this.setAction = this.setAction.bind(this);
  }

  componentDidMount() {
    const { loginUser } = this.props;
    this.isComponentMounted = true;
    if (!utils.is_obj_empty(loginUser) && utils_user.get_user_jwt()) {
      this.getActionCards();
    }
  }

  componentDidUpdate(prevProps) {
    const { loginUser, actionCards } = this.props;
    const { cards, errors } = this.state;

    if (!cards && !prevProps.actionCards && utils.is_obj_empty(loginUser)) {
      if (actionCards) {
        this.setState({ cards: actionCards });
      } else if (!errors.api && utils_user.get_user_jwt()) {
        this.getActionCards();
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onSurveyClick(survey) {
    const { history } = this.props;

    this.setState({ selectedSurvey: survey });
    if (utils.is_mobile()) {
      return history.push(`${PAGES.SURVEY_CONSENT}/${survey.survey_id}`, {
        survey,
      });
    }
    return this.setState({ showConsentModal: true });
  }

  getActionCards() {
    const { actioncards_get: actionCardsGet } = this.props;

    actionCardsGet(true)
      .then((response) => {
        if (!this.isComponentMounted) {
          return;
        }
        const { cacheIsDeleted } = this.state;
        const enabledCards = response.filter(({ enable }) => enable);
        const completedCards = enabledCards.filter(
          ({ status }) => status === "completed",
        );

        if (completedCards.length === enabledCards.length) {
          if (!cacheIsDeleted) {
            this.deleteActionCardsCache();
          }
        } else {
          this.setState({ cards: response });
        }
        this.setState({ cardsLoaded: true });
      })
      .catch((error) => {
        if (this.isComponentMounted) {
          this.setState({ errors: { api: error }, cardsLoaded: true });
        }
      });
  }

  setAction(location, surveyId) {
    const { surveys, history } = this.props;

    if (location.includes("survey")) {
      surveys.map((survey) =>
        surveyId === survey.survey_id ? this.onSurveyClick(survey) : null,
      );
    } else {
      history.push(location);
    }
  }

  deleteActionCardsCache() {
    const { actioncards_delete: actionCardsDelete } = this.props;
    actionCardsDelete(true)
      .then(() => {
        if (!this.isComponentMounted) {
          return;
        }
        this.getActionCards();
        this.setState({ cacheIsDeleted: true });
      })
      .catch((error) =>
        this.isComponentMounted
          ? this.setState({ errors: { api: error } })
          : null,
      );
  }

  renderCard(card, i) {
    const { history } = this.props;

    return (
      <div key={i} className="action-card-wrapper col-12 col-md-3 mb-2 ">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="action-card bg-secondary p-3 rounded clearfix"
          onClick={() => history.push(card.cta || PAGES.DASHBOARD)}
        >
          <div className="action-card-img">
            <div className="action-card-img-wrapper">
              <IconGenerator iconKey={i} iconType="actioncard" />
            </div>
          </div>
          <div className="action-card-description">
            <p className="color-brand">{card.title}</p>
            <p className="h4 color-dark">{card.detail}</p>
          </div>
          <div className="action-card-mobile-chevron">
            <div className="symbol-chevron" />
          </div>
        </div>
      </div>
    );
  }

  renderCards() {
    const { cards, showDetails } = this.state;

    return (
      <>
        <Button
          variant="link"
          className="mobile-action-cards-details-button"
          onClick={() => this.setState({ showDetails: !showDetails })}
        >
          {showDetails ? "Hide" : "Details"}
        </Button>
        {cards.map((card, index) => (
          <ActionCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            card={card}
            action={this.setAction}
            showDetails={showDetails}
          />
        ))}
      </>
    );
  }

  render() {
    const { t } = this.props;
    const { cards, showConsentModal, selectedSurvey, cardsLoaded } = this.state;
    if (cardsLoaded && (!cards || cards.length === 0)) {
      return null;
    }

    return (
      <section
        className={`action-cards section-component${!cards ? " action-cards-placeholder" : ""}`}
      >
        <h2 className="mb-3 h3">{t("What Can I Do Next")}</h2>
        <div className="row small-gutters">
          {cards ? this.renderCards() : <CardsPlaceHolder />}
        </div>
        <SurveyConsentModal
          fromSurvey={false}
          show={showConsentModal}
          onHide={() => this.setState({ showConsentModal: false })}
          survey={selectedSurvey}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, { actioncards_get, actioncards_delete })(
    withTranslation()(ActionCards),
  ),
);
