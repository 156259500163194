import { DropdownItem } from "../components/core/Dropdown/DropdownItem";

const items: DropdownItem[] = [
  { key: "AK", name: "Alaska" },
  { key: "AL", name: "Alabama" },
  { key: "AR", name: "Arkansas" },
  { key: "AS", name: "American Samoa" },
  { key: "AZ", name: "Arizona" },
  { key: "CA", name: "California" },
  { key: "CM", name: "Northern Mariana Islands" },
  { key: "CO", name: "Colorado" },
  { key: "CT", name: "Connecticut" },
  { key: "DC", name: "District of Columbia" },
  { key: "DE", name: "Delaware" },
  { key: "FL", name: "Florida" },
  { key: "GA", name: "Georgia" },
  { key: "GU", name: "Guam" },
  { key: "HI", name: "Hawaii" },
  { key: "IA", name: "Iowa" },
  { key: "ID", name: "Idaho" },
  { key: "IL", name: "Illinois" },
  { key: "IN", name: "Indiana" },
  { key: "KS", name: "Kansas" },
  { key: "KY", name: "Kentucky" },
  { key: "LA", name: "Louisiana" },
  { key: "MA", name: "Massachusetts" },
  { key: "MD", name: "Maryland" },
  { key: "ME", name: "Maine" },
  { key: "MI", name: "Michigan" },
  { key: "MN", name: "Minnesota" },
  { key: "MO", name: "Missouri" },
  { key: "MS", name: "Mississippi" },
  { key: "MT", name: "Montana" },
  { key: "NC", name: "North Carolina" },
  { key: "ND", name: "North Dakota" },
  { key: "NE", name: "Nebraska" },
  { key: "NH", name: "New Hampshire" },
  { key: "NJ", name: "New Jersey" },
  { key: "NM", name: "New Mexico" },
  { key: "NV", name: "Nevada" },
  { key: "NY", name: "New York" },
  { key: "OH", name: "Ohio" },
  { key: "OK", name: "Oklahoma" },
  { key: "OR", name: "Oregon" },
  { key: "PA", name: "Pennsylvania" },
  { key: "PR", name: "Puerto Rico" },
  { key: "RI", name: "Rhode Island" },
  { key: "SC", name: "South Carolina" },
  { key: "SD", name: "South Dakota" },
  { key: "TN", name: "Tennessee" },
  { key: "TT", name: "Trust Territories" },
  { key: "TX", name: "Texas" },
  { key: "UT", name: "Utah" },
  { key: "VA", name: "Virginia" },
  { key: "VI", name: "Virgin Islands" },
  { key: "VT", name: "Vermont" },
  { key: "WA", name: "Washington" },
  { key: "WI", name: "Wisconsin" },
  { key: "WV", name: "West Virginia" },
  { key: "WY", name: "Wyoming" },
];

const stateKeys = items.map(({ key }) => key);

const statesAbbreviated: DropdownItem[] = items.map(({ key }) => ({
  key,
  name: key,
}));

export { stateKeys, statesAbbreviated };
