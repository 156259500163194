import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import WorkflowWrapperComponent from "../components/WorkflowNext/WorkflowWrapperComponent";
import PAGES from "../constants/pages";
import {
  GenericWorkflowStep,
  WorkflowCodeType,
} from "../services/types/WorkflowTypes";
import { get_user_attributes_get } from "../actions/userAction";
import {
  selectCurrentStep,
  selectCurrentStepIsLoading,
} from "../store/Workflow/selector";

type OrderDnaKitStateProps = {
  currentStep: GenericWorkflowStep;
  isCurrentStepLoading?: boolean;
};

type OrderDnaKitProps = {
  get_user_attributes_get: () => Promise<{ data?: any; error?: any }>;
} & OrderDnaKitStateProps &
  RouteComponentProps;

function OrderDnaKit({
  currentStep,
  isCurrentStepLoading,
  history,
  get_user_attributes_get,
}: OrderDnaKitProps) {
  const workflowCode: WorkflowCodeType = "workflow_spitkit";

  useEffect(() => {
    if (currentStep === null && !isCurrentStepLoading) {
      get_user_attributes_get().then(() => history.push(PAGES.DASHBOARD));
    }
  }, [currentStep, isCurrentStepLoading]);

  const handleWorkflowExit = async () => {
    await get_user_attributes_get();
    history.push(PAGES.DASHBOARD);
  };

  // TODO: Fix types
  return (
    <WorkflowWrapperComponent
      // @ts-ignore
      workflowCode={workflowCode}
      // @ts-ignore
      onWorkflowExit={handleWorkflowExit}
    />
  );
}

const mapDispatchToProps = {
  get_user_attributes_get,
};

const mapStateToProps = createStructuredSelector<any, OrderDnaKitStateProps>({
  currentStep: selectCurrentStep,
  isCurrentStepLoading: selectCurrentStepIsLoading,
});

// @ts-ignore
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDnaKit),
);
