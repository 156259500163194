import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PAGES from "../../../constants/pages";
import { WORKFLOW, WORKFLOW_VALUE } from "../../../constants/workflow";
import { REGISTRATION_USER_STATUS } from "../../../constants/registration";
import { LOCAL_STORAGE } from "../../../constants/localStorage";
import { TOKEN_TYPE } from "../../../constants/tokenType";
import { UPDATE_SELF_ERROR_RESPONSES } from "../../../constants/errors";

import SignupProgress from "../../signup/signup_progress";
import SignUpForm from "../../signup/signup_form";
import SetPasswordForm from "../../password/set_password_form";

import { utils } from "../../../utils/utils_general";
import { utils_signup } from "../../../utils/utils_signup";
import { utils_registration_flow } from "../../../utils/utils_registration_flow";

import {
  registration_flow_get,
  update_registration_flow,
  set_registration_type,
  reset_registration_flow,
} from "../../../actions/registrationFlowAction";
import {
  create_self_register_post,
  check_signup_post,
  get_user_attributes_get,
} from "../../../actions/userAction";
import { login_post } from "../../../actions/loginAction";
import { self_reg_content_get } from "../../../actions/selfRegistrationContentAction";

import CategorizationValidation from "../../global/categorization_validation";
import Spinner from "../../global/spinner";
import TextMediaWithNext from "../../global/text_media_with_next";

import BlankComponent from "../../core/Blank/BlankComponent";
import { get_utm_params } from "../../../actions/utmParamsAction";

class Signup extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      currentStep: null,
      lastStep: null,
      gettingFlow: null,
      error: null,
      set_email: null,
      set_password: null,
      signupFields: null,
      questionnaires: {},
      showHardStop: false,
      reg_flow: null,
      submitLoader: false,
    };
  }

  stepComponent = {
    [WORKFLOW.SET_EMAIL]: () => (
      <>
        <h1 className="mb-3 mt-3">
          {this.props.t(this.state.currentStep.name)}
        </h1>
        <SignUpForm
          prevValues={this.state.set_email}
          signupFields={this.state.signupFields}
          signupErrors={this.state.error}
          onComplete={(flow, data) => this.onFormSubmit(flow, data)}
        />
        <div className="text-center mb-3">
          <Link to={PAGES.LOGIN}>
            <u>{this.t("Already have an account?")}</u>
          </Link>
        </div>
      </>
    ),
    [WORKFLOW.VALIDATION]: () => (
      <CategorizationValidation
        qn={this.state.currentStep.qn}
        prevValues={this.state.questionnaires}
        onComplete={(flow, data) => this.onFormSubmit(flow, data)}
      />
    ),

    [WORKFLOW.CATEGORIZATION]: () => (
      <>
        <CategorizationValidation
          qn={this.state.currentStep.qn}
          prevValues={this.state.questionnaires}
          onComplete={(flow, data) => this.onFormSubmit(flow, data)}
        />
      </>
    ),
    [WORKFLOW.SET_PASSWORD]: () => (
      <>
        <h1 className="mb-3 mt-3">
          {this.props.t(this.state.currentStep.name)}
        </h1>
        <br />
        <br />
        <SetPasswordForm
          selfRegistrationPasswordSubmit={(flow, data) =>
            this.onFormSubmit(flow, data)
          }
          disableButton={this.state.submitLoader}
          page="signup"
        />
      </>
    ),
    [WORKFLOW.TEXT_MEDIA]: () => (
      <>
        <h1 className="mb-3 mt-3">
          {this.props.t(this.state.currentStep.name)}
        </h1>
        <TextMediaWithNext
          data={this.state.currentStep}
          body={this.state.currentStep.body}
          onNext={() => this.onTextComponentNext()}
        />
      </>
    ),
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (!utils.is_obj_empty(this.state.errors)) {
      this.setState({ errors: {} });
    }
  }

  componentDidMount() {
    // reset everything on mount
    if (this.props.registrationType !== TOKEN_TYPE.REGISTRATION_SELF) {
      utils.clear_local_storage();
      this.props.reset_registration_flow();
    }

    this.getContent();
    this.prepRegFlow();
    this.props.get_utm_params();
  }

  getContent() {
    if (!this.props.selfRegContent) {
      const defaultSignupFields = [
        { label: "Email Address", name: "email", req: true },
      ];
      this.props
        .self_reg_content_get(true)
        .then((res) => this.setState({ signupFields: res.fields }))
        .catch(() => this.setState({ signupFields: defaultSignupFields }));
    } else {
      this.setState({ signupFields: this.props.selfRegContent.fields });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  prepRegFlow() {
    this.props.set_registration_type(TOKEN_TYPE.REGISTRATION_SELF);
    const reg_flow = this.props.registrationFlow;
    if (!reg_flow) {
      const onRegFlowSuccess = (flow) => this.setSteps(flow);
      this.getRegFlow(onRegFlowSuccess);
    } else {
      this.setSteps(reg_flow);
    }
  }

  getRegFlow(onRegFlowSuccess) {
    if (!this.state.gettingFlow) {
      this.setState({ gettingFlow: true });
      this.props
        .registration_flow_get(true)
        .then((res) => {
          onRegFlowSuccess(res);
          this.setState({ gettingFlow: false, reg_flow: res });
        })
        .catch(() => this.setState({ gettingFlow: false }));
    }
  }

  setSteps(flow) {
    if (flow) {
      const currentStep = flow.find((c) => !c.value) || flow[0];
      const lastStep = flow
        .slice()
        .reverse()
        .find((c) => c.status === REGISTRATION_USER_STATUS.NON_REGISTER);
      this.setState({
        currentStep,
        lastStep,
      });
      this.checkIfStepIsDone(flow, currentStep, lastStep);
    }
  }

  checkIfStepIsDone(reg_flow, currentStep) {
    if (
      currentStep &&
      currentStep.component !== WORKFLOW.SET_EMAIL &&
      currentStep.component !== WORKFLOW.CATEGORIZATION &&
      currentStep.component !== WORKFLOW.SET_PASSWORD &&
      currentStep.component !== WORKFLOW.VALIDATION &&
      currentStep.component !== WORKFLOW.TEXT_MEDIA
    ) {
      this.goToRegisteredNextStep(reg_flow);
    }
  }

  compileUserData(lastData) {
    const user = this.state.set_email;
    user.password = lastData.password || this.state.set_password.password;
    if (!utils.is_obj_empty(this.state.questionnaires)) {
      user.qn = this.state.questionnaires;
    }
    return user;
  }

  onFormSubmit(reg_flow, data) {
    if (data.email) {
      this.setState({ set_email: data });
    } else if (!data.password) {
      // setting possible categorization and validation
      const { questionnaires } = this.state;
      const key = Object.keys(data);
      questionnaires[key[0]] = data[key];
      this.setState({ questionnaires });
    }

    if (this.state.currentStep.name === this.state.lastStep.name) {
      this.onComplete(reg_flow, data);
    } else {
      this.updateRegistrationFlow(reg_flow);
    }
  }

  updateRegistrationFlow(flow) {
    this.props.update_registration_flow(flow);
    const currentStep = flow.find((c) => !c.value);
    this.setState({ currentStep });
  }

  // for non active components, just go next
  onTextComponentNext() {
    const firstNonCompleteTextMedia = this.props.registrationFlow.find(
      (c) => c.component === WORKFLOW.TEXT_MEDIA && !c.value,
    );
    const new_flow = this.props.registrationFlow.map((c) => {
      if (c.id === firstNonCompleteTextMedia.id) {
        c.value = WORKFLOW_VALUE.COMPLETE;
      }
      return c;
    });
    this.updateRegistrationFlow(new_flow);
  }

  onComplete(flow, lastData) {
    const user = {
      ...this.compileUserData(lastData),
    };
    if (this.props.utmParams) {
      user.utm = this.props.utmParams;
    }
    this.setState({ submitLoader: true });
    return this.props
      .create_self_register_post(user, true)
      .then((resp) => {
        const { token } = resp;
        this.props
          .login_post({ token }, true)
          .then(() => {
            this.setState({ error: null });
            // submit loader goes to false in next function
            return this.goToRegisteredNextStep(flow);
          })
          .catch(() => this.setState({ submitLoader: false }));
      })
      .catch((error) => {
        const errors = error.response.data
          ? error.response.data.msg
          : error.response.status;
        this.setState({ error: errors, submitLoader: false });
        if (
          errors.email === UPDATE_SELF_ERROR_RESPONSES.EMAIL_ALREADY_REGISTERED
        ) {
          const restartFlow = flow.map((c) => {
            c.value = null;
            return c;
          });
          this.updateRegistrationFlow(restartFlow);
        }
        return errors;
      });
  }

  goToRegisteredNextStep(reg_flow) {
    if (this.props.loginUser) {
      const signupAttr = reg_flow
        .filter(
          (c) =>
            c.status === REGISTRATION_USER_STATUS.NON_REGISTER &&
            c.exit_attribute[0] !== "*",
        )
        .map((c) => c.exit_attribute);
      const signupAttrFlat = [].concat.apply([], signupAttr);

      // we have our own spinner on this componet
      this.props
        .get_user_attributes_get(true)
        .then((res) => {
          const attr_errors = utils_registration_flow.checkAttrCompleted(
            signupAttrFlat,
            res,
          );
          if (attr_errors.length > 0) {
            // this.componentDidMount();
            console.log("error", attr_errors);
          } else {
            utils_signup.goToNextRegistrationStep(
              reg_flow,
              this.state.lastStep,
              res,
              this.props.update_registration_flow,
              this.props.history.push,
              this.props.post_workflow_complete_post,
            );
          }
          this.setState({ submitLoader: false });
        })
        .catch(() => this.setState({ submitLoader: true }));
    }
  }

  renderSignupComponent() {
    const { state } = this;

    if (!state.currentStep) {
      return <BlankComponent />;
    }
    const componentToRender = this.stepComponent[state.currentStep.component];
    if (!componentToRender) {
      return <Spinner error="signup workflow component not specified" />;
    }
    return componentToRender();
  }

  render() {
    const reg_flow =
      this.props.registrationFlow ||
      utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    return (
      <>
        {/* {!this.props.selfRegContent ? <PageLoader loader={!reg_flow || !this.state.signupFields} /> : null} */}
        <section className="signup-component signup">
          <SignupProgress
            currentStep={this.state.currentStep}
            regFlow={reg_flow}
          />

          <div
            className={`signup-body-wrapper ${this.state.currentStep && this.state.currentStep.component === WORKFLOW.SET_EMAIL ? "signup-form-wrapper" : " "}`}
          >
            {this.renderSignupComponent(reg_flow)}
          </div>
          {this.state.submitLoader ? <Spinner error="submit signup" /> : null}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});
export default withRouter(
  connect(mapStateToProps, {
    registration_flow_get,
    update_registration_flow,
    set_registration_type,
    create_self_register_post,
    login_post,
    check_signup_post,
    self_reg_content_get,
    get_user_attributes_get,
    reset_registration_flow,
    get_utm_params,
  })(withTranslation("fields")(Signup)),
);
