import { utils } from "./utils_general";
import { LOCAL_STORAGE } from "../constants/localStorage";
import {
  isContainerForLegacyCodeInitialized,
  legacyGetService,
} from "../services/ServiceProvider";

export const utils_user = {
  get_user_jwt: () => utils.get_local_storage(LOCAL_STORAGE.USER) || null,
  get_user: () =>
    isContainerForLegacyCodeInitialized()
      ? legacyGetService("userService").getCurrentUser()
      : null,
  get_user_name: (user) =>
    user.first_name
      ? user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.first_name
      : "",
  is_logged: (user) => user && !utils.is_obj_empty(user),
};
