import { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import noHospital from "../../assets/images/no-hospital.svg";
import noDoctor from "../../assets/images/no-doctor.svg";
import EmrEmptyResultsThankYouBanner from "./emr_empty_results_thank_you_banner";
import Spinner from "../global/spinner";

const EmptyResults = ({
  searchType,
  onFeedbackSubmit,
  onSkip,
  onFinished,
  show = false,
}) => {
  const { t } = useTranslation();
  const feedbackRef = useRef();
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTextEmpty, setIsTextEmpty] = useState(true);

  const getContentBysearchType = () => {
    const content = {
      doctor: {
        image: noDoctor,
        title: t("Sorry! We couldn't find your doctor"),
        subtitle: t("Try searching by doctor's name"),
      },
      healthSystem: {
        image: noHospital,
        title: t("Sorry! We couldn't find your health system"),
        subtitle: t("Try searching by Health System"),
      },
    };
    return content[searchType] || content.healthSystem;
  };

  const handleTextChange = (e) => {
    setIsTextEmpty(!e.target.value.trim());
  };

  const handleFeedbackSubmit = async () => {
    const feedbackMessage = feedbackRef.current.value.trim();
    if (!feedbackMessage) {
      return;
    }
    setIsLoading(true);

    try {
      await onFeedbackSubmit({
        feedback: feedbackMessage,
        search_type: searchType,
      });
      feedbackRef.current.value = "";
      setIsTextEmpty(true);
      setShowBanner(true);
    } catch (err) {
      console.error("Failed to send feedback:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnRequestClose = () => {
    setShowBanner(false);
    onFinished();
  };

  const content = getContentBysearchType();

  if (!show) return null;

  return (
    <div className="emr-empty-results">
      <img src={content.image} alt="no-results" />
      <h1>{content.title}</h1>
      <h2>{content.subtitle}</h2>
      <div className="emr-empty-results-feedback">
        <p>
          {t(
            "If you still can't find who you are looking for, leave us a message",
          )}
        </p>
        <Form.Control
          as="textarea"
          ref={feedbackRef}
          onChange={handleTextChange}
          placeholder={t("Share some details about who you are looking for.")}
        />
        {isLoading && (
          <Spinner
            data={{
              classes: "my-2",
            }}
          />
        )}
        <Button
          className="btn btn-primary btn-sm"
          onClick={handleFeedbackSubmit}
          disabled={isTextEmpty}
        >
          {t("Send Message")}
        </Button>
        <div>
          <Button
            variant="link"
            className="emr-empty-results-skip"
            onClick={onSkip}
          >
            {t("I'd like to skip this step for now")}
          </Button>
        </div>
      </div>
      <EmrEmptyResultsThankYouBanner
        show={showBanner}
        searchType={searchType}
        onRequestClose={handleOnRequestClose}
        onSkip={onSkip}
      />
    </div>
  );
};

export default EmptyResults;
