import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ContextProvider } from "../../services/ServiceProvider";
import { doSetConsentLoading } from "../../store/Consent/ConsentActions";
import Spinner from "../global/spinner";
import { utils } from "../../utils/utils_general";
import { USER_ACTION } from "../../constants/userAction";
import { post_user_action_post } from "../../actions/userAction";
import { URL_ROOT_API } from "../../constants/api";

class ConsentDownload extends React.Component {
  static contextType = ContextProvider;

  constructor() {
    super();
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    const { container } = this.context;
    this.consentService = container.get("consentService");
  }

  onDownload() {
    const { consentCode, doSetConsentLoading } = this.props;
    this.consentService
      .getFile(consentCode, { doSetConsentLoading })
      .then((res) => {
        window.location.assign(`${URL_ROOT_API}/download?token=${res}`);
        setTimeout(() => {
          this.updateUserAction();
          this.props.onComplete();
        }, 3000);
      })
      .catch((error) => this.setState({ error: { system: error } }));
  }

  updateUserAction() {
    return this.props
      .post_user_action_post(USER_ACTION.CONSENT_DOWNLOAD, true)
      .then((response) => {})
      .catch((error) => {
        this.setState({ error: { system: JSON.stringify(error) } });
        // show message and go to dash?
        setTimeout(() => {
          this.props.onComplete();
        }, 3000);
      });
  }

  skipDownload() {
    this.props.onComplete();
  }

  render() {
    const { spinner } = this.props;

    return (
      <Modal
        show={this.props.showDownload}
        onHide={() => this.onCloseClick()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`consent-modal ${utils.get_browser()}`}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className="h4">{this.props.t("Download Consent")}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 font-weight-bold">
            {this.props.t(
              "We recommend downloading your consent so you have access to your records and rights in case you need them later",
            )}
          </p>
          <div className="text-center mt-4">
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => this.onDownload()}
            >
              {this.props.t("Download")}
            </Button>
          </div>
          <div className="mt-2 text-center">
            <Button
              variant="link"
              className="btn-link-callout p-0 m-0"
              onClick={() => this.skipDownload()}
            >
              {this.props.t("Skip")}
            </Button>
          </div>
          {
            // use Spinner here because the global Spinner is hidden behind the modal
            spinner ? <Spinner data={spinner} /> : null
          }
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

const mapDispatchToProps = {
  post_user_action_post,
  doSetConsentLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ConsentDownload));
