/**
 * @file components/survey/survey_side_details.js
 */

import React from "react";
import { Link } from "react-router-dom";
import PAGES from "../../constants/pages";
import { withTranslation } from "react-i18next";

const SurveySideDetails = (props) => {
  const { survey } = props;
  if (!survey) {
    return null;
  }
  return (
    <div className="section-component">
      <h1 className="mb-3 side-heading">{survey.title}</h1>
      <div>
        <Link
          to={PAGES.SURVEYS}
          className="btn button primary-invert mt-3 block"
        >
          {props.t("Back to surveys")}
        </Link>
      </div>
    </div>
  );
};
export default withTranslation()(SurveySideDetails);
