import React from "react";
import { GenericWorkflowStepDetails } from "../../services/types/WorkflowTypes";
import { Button, ButtonProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PAGES from "../../constants/pages";

export type WorkflowStepDetailsProps = {
  name?: string;
  onPauseClick?: () => Promise<any>;
} & GenericWorkflowStepDetails &
  RouteComponentProps;

function ResponsiveButton(props: ButtonProps) {
  const { children } = props;
  return (
    <>
      <Button {...props} block variant="primary" className="d-none d-lg-block">
        {children}
      </Button>
      <Button
        {...props}
        block
        variant="link"
        className="mb-3 font-weight-normal text-left d-lg-none"
      >
        {children}
      </Button>
    </>
  );
}

const WorkflowStepDetails = withRouter(
  ({
    subtitle,
    description,
    allow_pause,
    name,
    history,
    onPauseClick,
  }: WorkflowStepDetailsProps) => {
    const { t } = useTranslation();

    const handlePauseWorkflow = () => {
      if (typeof onPauseClick === "function") {
        onPauseClick();
      }
      history.push(PAGES.DASHBOARD);
    };

    return (
      <>
        {name ? <h2 className="mb-3 h3">{t(name)}</h2> : null}
        {subtitle ? <p className="mb-3">{t(subtitle)}</p> : null}
        {description ? <p className="mb-3">{t(description)}</p> : null}
        {allow_pause ? (
          <ResponsiveButton onClick={handlePauseWorkflow}>
            {t("Return to Dashboard")}
          </ResponsiveButton>
        ) : null}
      </>
    );
  },
);

export { WorkflowStepDetails };
