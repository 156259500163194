import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  GenericWorkflow,
  WorkflowCodeType,
} from "../../services/types/WorkflowTypes";
import WorkflowHeaderComponent from "./WorkflowHeader/WorkflowHeaderComponent";
import WorkflowStepsComponent from "./WorkflowStepsComponent";

type WorkflowComponentProps = {
  workflow: GenericWorkflow | null;
  workflowName: string | null;
  workflowCode: WorkflowCodeType | null;
  isWorkflowLoading?: boolean;
  onWorkflowExit: () => Promise<any>;
  onWorkflowSkip: () => Promise<any>;
} & RouteComponentProps;

function WorkflowComponent({
  workflow,
  workflowCode,
  workflowName,
  isWorkflowLoading = false,
  onWorkflowExit,
  onWorkflowSkip,
}: WorkflowComponentProps) {
  const { t } = useTranslation();

  const workflowNamei18ned = workflowCode ? t(workflowCode) : null;
  const workflowNameResolved =
    workflowName || workflowNamei18ned?.toString() || workflowCode;

  // TODO: fix types
  return (
    <>
      <WorkflowHeaderComponent
        // @ts-ignore
        workflowName={workflowNameResolved}
        // @ts-ignore
        workflow={workflow}
        // @ts-ignore
        isWorkflowLoading={isWorkflowLoading || false}
      />
      <section className="container">
        <WorkflowStepsComponent
          // @ts-ignore
          workflowCode={workflowCode}
          // @ts-ignore
          onSkipWorkflow={onWorkflowSkip}
          // @ts-ignore
          onExitWorkflow={onWorkflowExit}
        />
      </section>
    </>
  );
}

export default withRouter(WorkflowComponent);
