import { isEmpty } from "lodash/fp";
/* eslint-disable camelcase */
import { utils_registration_flow } from "../../../utils/utils_registration_flow";
/* eslint-enable camelcase */
import { useEffect, useState } from "react";
import {
  ConsentFlowStep,
  GenericRegistrationFlowStep,
  GetUserAttributesAction,
  UserAttribute,
} from "../types";

type GetAttributesArguments = {
  /* eslint-disable camelcase */
  get_user_attributes_get: GetUserAttributesAction;
  /* eslint-enable camelcase */
  isLoginRenewed?: boolean;
};

export function useGetConsentsArray(
  mandatoryConsentFlow: ConsentFlowStep[] | null | undefined,
  consentCode: string | null,
) {
  const [consentComponentsArray, setConsentsArray] = useState<
    ConsentFlowStep[]
  >([]);

  useEffect(() => {
    const newConsentsArray: ConsentFlowStep[] = [];
    if (mandatoryConsentFlow && !isEmpty(mandatoryConsentFlow) && consentCode) {
      mandatoryConsentFlow.forEach((consentComponent: ConsentFlowStep) => {
        if (consentCode === consentComponent.consent_code) {
          newConsentsArray.push(consentComponent);
        }
      });
    }
    setConsentsArray(newConsentsArray);
  }, [mandatoryConsentFlow, consentCode]);

  return consentComponentsArray;
}

export function useFormConsentsStepsArray(
  mandatoryConsentFlow: ConsentFlowStep[] | null | undefined,
  userAttributes: UserAttribute[] | string | null,
) {
  const [consentComponentsArray, setConsentComponentsArray] = useState<
    ConsentFlowStep[]
  >([]);

  useEffect(() => {
    const newMandatoryFlow: ConsentFlowStep[] = [];

    if (!isEmpty(userAttributes) && mandatoryConsentFlow) {
      mandatoryConsentFlow.forEach((consentComponent: ConsentFlowStep) => {
        const entryErrors = utils_registration_flow.checkAttrCompleted(
          consentComponent.entry_attribute,
          userAttributes,
        );
        if (isEmpty(entryErrors) || consentComponent.value) {
          newMandatoryFlow.push(consentComponent);
        }
      });
    }

    setConsentComponentsArray(newMandatoryFlow);
  }, [mandatoryConsentFlow, userAttributes]);

  return consentComponentsArray;
}

// eslint-disable-next-line camelcase,no-shadow
export function useGetUserAttributes({
  get_user_attributes_get,
}: GetAttributesArguments) {
  const [userAttributes, setUserAttributes] = useState<UserAttribute[] | null>(
    null,
  );

  const fetchUserAttributes = async () => {
    const attributes = await get_user_attributes_get();
    setUserAttributes(attributes);
  };

  useEffect(() => {
    fetchUserAttributes();
  }, []);

  return userAttributes;
}

type LoginRenewalAttr = [GenericRegistrationFlowStep[], () => Promise<any>];
export function useLoginRenewal(deps: LoginRenewalAttr) {
  const [isLoginRenewed, setLoginRenewed] = useState<boolean>(false);

  // eslint-disable-next-line camelcase
  const [defaultFlow, login_get] = deps;

  useEffect(() => {
    let isCancelled = false;
    // eslint-disable-next-line consistent-return
    const fetchLogin = async () => {
      // eslint-disable-next-line camelcase
      if (isCancelled || !login_get) {
        return null;
      }
      const result = await login_get();
      if (result) {
        setLoginRenewed(true);
      }
    };

    fetchLogin();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line camelcase
  }, [defaultFlow, login_get]);

  return isLoginRenewed;
}
