/**
 * @file components/login/login.jsx
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"; // eslint-disable-line no-unused-vars
import { Form, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

import { withService } from "../../services/ServiceProvider";

/* eslint-disable camelcase */
import { utils } from "../../utils/utils_general";
import { CONFIG } from "../../constants/config";

import { login_post } from "../../actions/loginAction";
import { reset_registration_flow } from "../../actions/registrationFlowAction";

import {
  LOGIN_ERROR_DISPLAY,
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
  FIELD_ERROR_DISPLAY,
} from "../../constants/errors";
import { OTP_LOGIN_FLOWS } from "../../constants/otp";
import PAGES from "../../constants/pages";
import { TOKEN_TYPE } from "../../constants/tokenType";
import { LOCAL_STORAGE } from "../../constants/localStorage";

/* eslint-ebable camelcase */

class LoginPassword extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
    this.emailField = React.createRef();
    this.passwordField = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const email = location.state?.email;
    if (email) {
      this.setState({ email });
    }
    this.props.reset_registration_flow();
    i18n.loadNamespaces("fields", (err, t) => {
      /* ... */
    });
  }

  handleSubmit(e) {
    const { validationService } = this.props;

    e.preventDefault();
    this.setState({ errors: {} });
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    // const errors = utils_validation.validate(login_credential, data);

    const { error } = validationService.validateData("LOGIN_VALIDATION", data);

    if (!error) {
      this.submitLogin(data);
      return;
    }

    const { details } = error;
    if (!details) {
      return;
    }
    const errorState = Object.create(null);
    details.forEach((detail) => (errorState[detail.path[0]] = detail.message));

    this.setState({ errors: errorState });
  }

  submitLogin(data) {
    this.props
      .login_post(data)
      .then((user) => {
        const valid_login_session = [
          TOKEN_TYPE.PASSWORD,
          TOKEN_TYPE.MFA_OTP,
          TOKEN_TYPE.SESSION,
          TOKEN_TYPE.REGISTRATION_SELF,
          TOKEN_TYPE.REGISTRATION_SUBACCOUNT,
          TOKEN_TYPE.REGISTRATION_ADMIN,
          TOKEN_TYPE.ENROLLMENT,
        ];
        if (!valid_login_session.includes(user.token_type)) {
          this.setState({
            errors: { system: LOGIN_ERROR_DISPLAY.SESSION_INVALID },
          });
        } else if (new Date(user.expiry) < new Date()) {
          this.setState({
            errors: { system: LOGIN_ERROR_DISPLAY.SESSION_EXPIRED },
          });
        } else {
          utils.remove_local_storage(LOCAL_STORAGE.OTP_ID);
          this.props.history.push({
            pathname: PAGES.MFA_OTP,
            state: { from: OTP_LOGIN_FLOWS.PASSWORD },
          });
        }
      })
      .catch((error) => {
        const M = LOGIN_ERROR_DISPLAY;
        if (error.response) {
          this.focusField();
          switch (error.response.status) {
            case RESPONSE_CODE["401_unauthorized"]:
              this.setState({ errors: { system: M.USER_NOT_FOUND } });
              break;
            case RESPONSE_CODE["410_user_not_found"]:
              this.setState({ errors: { system: M.USER_NOT_FOUND } });
              break;
            case RESPONSE_CODE["411_wrong_password"]:
              this.setState({ errors: { system: M.USER_NOT_FOUND } });
              break;
            case RESPONSE_CODE["417_user_pending"]:
              this.setState({ errors: { system: M.USER_NOT_FOUND } });
              break;
            case RESPONSE_CODE["414_user_lockout"]:
              this.setState({ errors: { system: M.USER_LOCKOUT } });
              break;
            case RESPONSE_CODE["415_user_suspended"]:
              this.setState({ errors: { system: M.USER_SUSPENDED } });
              break;
            case RESPONSE_CODE["416_user_deleted"]:
              this.setState({ errors: { system: M.USER_DELETED } });
              break;
            default:
              this.setState({ errors: { system: UNEXPECTED_ERROR } });
          }
        }
      });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (!utils.is_obj_empty(this.state.errors)) {
      this.setState({ errors: {} });
    }
  }

  focusField() {
    if (this.state.errors.password && !this.state.errors.email) {
      this.passwordField.current.focus();
    } else {
      this.emailField.current.focus();
    }
  }

  handleFocus = (event) => event.target.select();

  renderPasswordError = () => {
    const { t } = this.props;
    const { errors } = this.state;

    if (!errors.password) {
      return null;
    }
    return FIELD_ERROR_DISPLAY[errors.password]
      ? t(FIELD_ERROR_DISPLAY[errors.password])
      : t(FIELD_ERROR_DISPLAY.err_field_required);
  };

  renderLoginForm() {
    const { t, location } = this.props;
    const { errors } = this.state;

    const email = location.state?.email;
    return (
      <Form noValidate onSubmit={(e) => this.handleSubmit(e)}>
        <Form.Group controlId="formBasicEmail">
          <div className="pb-3">
            <Form.Label>{t("Email Address")}</Form.Label>
          </div>
          <Form.Control
            onChange={(e) => this.handleChange(e)}
            name="email"
            type="email"
            aria-required="true"
            ref={this.emailField}
            onFocus={this.handleFocus}
            defaultValue={email}
          />
          <Form.Text className="text-danger form-error" aria-live="polite">
            {errors.email ? t("Please provide a valid email.") : <br />}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <div className="pb-3">
            <Form.Label>{t("Password")}</Form.Label>
          </div>
          <Form.Control
            onChange={(e) => this.handleChange(e)}
            name="password"
            type="password"
            aria-required="true"
            ref={this.passwordField}
            onFocus={this.handleFocus}
          />
          <Form.Text className="text-danger" aria-live="polite">
            {t(this.renderPasswordError())}
            <br />
            {t(this.state.errors.system)}
          </Form.Text>
        </Form.Group>

        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center flex-wrap mb-3 border-bottom pb-4">
          <Link
            className="px-5 pb-4 pb-sm-0"
            to={PAGES.RESET_PASSWORD_SEND_EMAIL}
          >
            <u>{t("Forgot Password?")}</u>
          </Link>
          <Button variant="primary" type="submit">
            {t("Continue")}
          </Button>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-end flex-wrap">
          {CONFIG.MAGIC_LINK_ENABLED ? (
            <div className="text-center text-sm-left pt-3 pb-1 flex-fill">
              <Link to={{ pathname: PAGES.LOGIN, state: { email } }}>
                {t("Sign in with a link instead")}
              </Link>
            </div>
          ) : null}

          <div className="text-center text-sm-right pt-3 pb-1 flex-fill">
            <span className="pr-1">{t("Don’t have an account?")}</span>{" "}
            <Link to={PAGES.SIGNUP}>{t("Sign Up")}</Link>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <section>
        <div className="login unauth-body-wrapper">
          <div>
            <h1 className="mb-4">{this.t("Sign In")}</h1>
          </div>
          <div>{this.renderLoginForm()}</div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, {
    login_post,
    reset_registration_flow,
  })(
    withTranslation("fields")(
      withService(["validationService"])(LoginPassword),
    ),
  ),
);
